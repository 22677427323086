import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { BUSINESS_LANGUAGES } from '../../../../../common/constants';
import LanguageSelector from '../../../../../components/languageSelector/LanguageSelector';
import useModal from '../../../../../components/modal/useModal';
import { useBusinessesQuery } from '../../../../../reducers/business/businessApiSlice';

const StoreDetails = ({ control, register, watch, errors, setValue }) => {
	const { id } = useModal();
	const { data: businesses } = useBusinessesQuery({ path: { id } });

	const businessId = watch('businessId');
	const title = watch('title');
	const description = watch('description');
	const streetAddress = watch('location.street_address');
	const translations = BUSINESS_LANGUAGES.reduce((acc, lang) => {
		acc[lang?.code] = {
			title: watch(`translations[${lang?.code}].title`),
			description: watch(`translations[${lang?.code}].description`),
		};
		return acc;
	}, {});
	const [teamNotesLang, setTeamNotesLang] = useState(BUSINESS_LANGUAGES[0].code);
	const handleLanguageChange = (language: string) => setTeamNotesLang(language);

	// Google maps autocomplete
	const autoCompleteRef = useRef();
	const inputRef = useRef(null);
	const options = {
		componentRestrictions: { country: 'gr' },
		fields: ['address_components', 'geometry', 'name'],
		types: ['address'],
	};
	let address: {
		location: {
			street_address: string;
			city: string;
			area: string;
			post_code: string;
			country: string;
			coords: {
				type: string;
				coordinates: [any, any];
			};
			translations: {
				[key: string]: {
					area: string;
					city: string;
					post_code: string;
					country: string;
					street_address: string;
				};
			};
		};
	} = {
		location: {
			street_address: '',
			city: '',
			area: '',
			post_code: '',
			country: '',
			coords: {
				type: 'Point',
				coordinates: ['', ''],
			},
			translations: {
				en: {
					area: '',
					city: '',
					post_code: '',
					country: '',
					street_address: '',
				},
			},
		},
	};

	const intl = useIntl();

	const handleAddressChange = () => {
		// @ts-ignore
		autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
		// @ts-ignore
		autoCompleteRef.current.addListener('place_changed', async () => {
			// @ts-ignore
			const { name, geometry, address_components } = await autoCompleteRef.current.getPlace();
			const { location } = address;
			location.coords.coordinates = [geometry.location.lat(), geometry.location.lat()];
			setValue('location.coords', { type: 'Point', coordinates: location.coords.coordinates });
			address_components.forEach((address: { types: string | string[]; long_name: string; short_name: string }) => {
				if (address.types.includes('street_number')) {
					location.street_address = `${address.long_name}`;
					setValue('location.street_address', location.street_address);
				}
				if (address.types.includes('route')) {
					location.street_address = `${name}`;
					setValue('location.street_address', location.street_address);
				}
				if (address.types.includes('locality')) {
					location.city = address.long_name;
					setValue('location.city', location.city);
				}
				if (address.types.includes('administrative_area_level_1') || address.types.includes('administrative_area_level_3')) {
					location.area = address.short_name;
					setValue('location.area', location.area);
				}
				if (address.types.includes('postal_code')) {
					location.post_code = address.long_name;
					setValue('location.post_code', location.post_code);
					setValue('location.translations.en.post_code', location.post_code);
				}
				if (address.types.includes('country')) {
					location.country = address.long_name;
					setValue('location.country', location.country);
					setValue('location.translations.en.country', address.short_name);
				}
			});
		});
	};

	useEffect(() => {
		handleAddressChange();
	}, []);

	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS" />
					</div>
				</div>
			</div>
			<div className="p-3 generic-border">
				<LanguageSelector language={teamNotesLang} onLanguageChange={handleLanguageChange} />
				<div className="row pt-5">
					<Controller
						name="business"
						control={control}
						render={({ field }) => (
							<div className="col-12 col-sm-6">
								<div className="pb-3 fs-7 fw-bold">
									<FormattedMessage id="STORE.MODAL.DETAILS.ORGANIZATION" />
									<sup>*</sup>
								</div>
								<select
									className="form-select form-select-solid text-capitalize"
									aria-label="Select example"
									disabled={id}
									defaultValue={businessId}
									{...register('businessId')}>
									{businesses &&
										businesses?.businesses.map((business: { title: string; _id: string }, index: number) => (
											<option value={business?._id} key={index} className="">
												{business?.title}
											</option>
										))}
								</select>
							</div>
						)}
					/>

					<div className="col-12 col-sm-6">
						<div className="input-group">
							<label htmlFor="location-business-title" className="form-label pb-3 pt-5 pt-sm-0 mb-0 fs-7 fw-bold">
								<FormattedMessage id="STORE.MODAL.DETAILS.TITLE" />
								<sup>*</sup>
							</label>

							<input
								type="text"
								className={clsx('form-control fs-6 p-4', {
									'is-invalid': errors?.title && translations[teamNotesLang]?.title === '',
								})}
								id="location-business-title"
								aria-labelledby="location-business-title"
								placeholder={intl.formatMessage({ id: 'STORE.MODAL.DETAILS.TITLE' })}
								{...register(`translations[${teamNotesLang}.title` || 'title')}
								defaultValue={translations[teamNotesLang]?.title ? translations[teamNotesLang]?.title : title}
								value={translations[teamNotesLang]?.title !== '' ? translations[teamNotesLang]?.title : title}
								onChange={(e) => {
									setValue(`translations[${teamNotesLang}].title`, e.target.value);
									setValue('title', e.target.value);
								}}
							/>
							{errors?.title && translations[teamNotesLang]?.title === '' ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.title.message}</span>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="location-description" className="form-label pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="STORE.MODAL.DETAILS.DESCRIPTION" />
							<sup>*</sup>
						</label>
						<textarea
							id="location-description"
							aria-labelledby="location-description"
							rows={4}
							cols={50}
							{...register(`translations[${teamNotesLang}.description` || 'description')}
							defaultValue={translations[teamNotesLang]?.description ? translations[teamNotesLang]?.description : description}
							value={translations[teamNotesLang]?.description !== '' ? translations[teamNotesLang]?.description : description}
							onChange={(e) => {
								setValue(`translations[${teamNotesLang}].description`, e.target.value);
								setValue('description', e.target.value);
							}}
							className={clsx('full-width-textarea fs-6 p-4', {
								'is-invalid': errors?.description && translations[teamNotesLang]?.description === '',
							})}
						/>
						{errors?.description && translations[teamNotesLang]?.description === '' ? (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.description.message}</span>
								</div>
							</div>
						) : null}
					</div>
				</div>
				<div className="row pt-5">
					<div className="col-12">
						<div className="input-group">
							<label htmlFor="location-business-address" className="form-label pb-3 mb-0 fs-7 fw-bold">
								<FormattedMessage id="STORE.MODAL.DETAILS.ADDRESS" />
								<sup>*</sup>
							</label>
							<Controller
								name="location.street_address"
								control={control}
								render={({ field: { value } }) => (
									<input
										defaultValue={value}
										className={clsx('form-control fs-6 p-4')}
										id="location-business-address"
										aria-labelledby="location-business-address"
										placeholder={intl.formatMessage({ id: 'STORE.MODAL.DETAILS.ADDRESS' })}
										ref={inputRef}
									/>
								)}
							/>

							{errors?.location?.street_address ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.location?.street_address.message}</span>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StoreDetails;
