import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { BUSINESS_LANGUAGES } from '../../../../common/constants';
import useModal from '../../../../components/modal/useModal';
import { capitalizeFirstLetter } from '../../../../components/utils/Capitilizer';
import { isEmpty } from '../../../../helpers/utils';
import { useBusinessesQuery } from '../../../../reducers/business/businessApiSlice';
import { selectActiveBusiness } from '../../../../reducers/business/businessSlice';
import { setShowModal } from '../../../../reducers/modalSlice';
import { useCreateStoreMutation, useEditStoreMutation, useGetStoreDetailsQuery } from '../../../../reducers/stores/storeApiSlice';
import { DAYS } from '../utils/options';
import details from './details/Details';
import { optionsCategory, optionsCurrency, optionsPhoneNumber, optionsTimezone } from './details/StoreOptions';
import { acceptingBookingOptions, managingTablesOptions } from './settingsComponents/settingsOptions';
import TabDetails from './TabDetails';
import TabFiles from './TabFiles';
import TabOpeningHours from './TabOpeningHours';
import TabPayments from './TabPayments';
import TabSettings from './TabSettings';

const StoreModalBody = () => {
	const dispatch = useDispatch();
	const { id, ref } = useModal();
	const editMode = !!id;
	const { _id: business_id } = useSelector(selectActiveBusiness);
	const { data: detailsData, isLoading: isLoadingDetails } = useGetStoreDetailsQuery({ bid: business_id, path: { id: id } }, { skip: !editMode });
	const { data: businessData } = useBusinessesQuery({ path: { id } });

	const getStoreValues = (key) => {
		if (detailsData?.item[key].length > 0) {
			return detailsData?.item[key].map((item) => ({
				value: item?._id,
				label: capitalizeFirstLetter(item?.title.toLowerCase()),
			}));
		}
	};

	const getStoreTraitValues = (key) =>
		detailsData?.item['traits']
			.filter((item) => item?.type === key)
			.map((item) => ({
				value: item?._id,
				label: capitalizeFirstLetter(item?.title),
			}));

	const phoneRegex = new RegExp('^(' + optionsPhoneNumber.map((option) => option.value.replace('+', '\\+')).join('|') + ')');
	const getSocialValues = () =>
		detailsData && editMode
			? detailsData?.item['contact_info']
					.map((item) => ({
						val: item.val,
						type: item?.type,
					}))
					.filter((social) => social.type !== 'phone' && social.type !== 'email')
			: [];

	const getBusinessName = () => {
		const title = businessData?.businesses.some((business) => business.title === details?.item?.business?.title);
		return title ? details?.item?.business?.title : businessData?.businesses ? businessData.businesses[0].title : '';
	};

	const getCurrency = () => `${detailsData?.item?.currency?.code}, ${detailsData?.item?.currency?.name} (${detailsData?.item?.currency?.symbol})`;
	const getReservationValues = () =>
		detailsData && editMode
			? detailsData?.item?.reservation_prefs.map((item) => ({
					value: item?._id,
					label: item?.title,
			  }))
			: [];

	const getTranslations = (schedule) => {
		const keys = [];

		BUSINESS_LANGUAGES?.forEach((i) => {
			keys.push({
				[i.code]: {
					notes: schedule?.translations[i?.code]?.notes ?? '',
				},
			});
		});
		return keys.reduce((acc, item) => {
			const [key, value] = Object.entries(item)[0];
			acc[key] = value;
			return acc;
		}, {});
	};

	const getOpeningHoursValues = () => {
		if (editMode) {
			let schedule = { ...detailsData?.item?.opening_hours };
			Object.keys(schedule).forEach((key) => {
				schedule[key] = {
					day: DAYS[key],
					time: `${schedule[key].start_time} - ${schedule[key].end_time}`,
					_id: uuidv4(),
					...schedule[key],
					translations: schedule[key].translations ? getTranslations(schedule[key]) : { en: '', el: '' },
				};
			});
			return schedule;
		}
		return [];
	};

	const validationSchema = Yup.object({
		title: Yup.string().required('Title is required'),
		description: Yup.string().required('Description is required'),
		email: Yup.string().required('Email is required').email('Invalid email format'),
		location: Yup.object().shape({
			street_address: Yup.string().required('Location is required'),
		}),
		phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone Number is required and must be 10 digits'),
		cuisine: Yup.array().min(1, 'At least 1 cuisine is required').required('Please select cuisine'),
		store_type: Yup.array().min(1, 'At least 1 category is required').required('Please select a category'),
	});

	let storeValues;
	useEffect(() => {
		if (!isLoadingDetails) {
			storeValues = {
				business: editMode ? getBusinessName() : '',
				businessId: business_id,
				payment_rules_temp: [
					{
						title: '',
						terms: '',
						rule_type: 'per_booking',
						type: 'prepayment',
						amount: 1,
						min_prepayment_guests: 1,
						_id: uuidv4(),
					},
				],
				country_code: '+30',
				phone: detailsData?.item?.phone.replace(phoneRegex, '') ?? '',
				email: detailsData?.item?.email ?? '',
				title: detailsData?.item?.title ?? '',
				description: detailsData?.item?.description ?? '',
				location: {
					street_address: detailsData?.item?.location?.street_address ?? '',
					city: detailsData?.item?.location?.city ?? '',
					area: detailsData?.item?.location?.area ?? '',
					post_code: detailsData?.item?.location?.post_code ?? '',
					country: detailsData?.item?.location?.country ?? 'GR',
					coords: detailsData?.item?.location?.coords ?? {},
					translations: {
						en: {
							street_address: editMode ? detailsData?.item?.location?.translations?.en?.street_address ?? '' : '',
							city: editMode ? detailsData?.item?.location?.translations?.en?.city ?? '' : '',
							area: editMode ? detailsData?.item?.location?.translations?.en?.area ?? '' : '',
							post_code: editMode ? detailsData?.item?.location?.translations?.en?.post_code ?? '' : '',
							country: editMode ? detailsData?.item?.location?.translations?.en?.country ?? 'GR' : 'GR',
						},
						el: {
							street_address: editMode ? detailsData?.item?.location?.translations?.el?.street_address ?? '' : '',
							city: editMode ? detailsData?.item?.location?.translations?.el?.city ?? '' : '',
							area: editMode ? detailsData?.item?.location?.translations?.el?.area ?? '' : '',
							post_code: editMode ? detailsData?.item?.location?.translations?.el?.post_code ?? '' : '',
							country: editMode ? detailsData?.item?.location?.translations?.el?.country ?? 'GR' : '',
						},
					},
				},
				contact_info:
					{
						social: editMode ? getSocialValues() : setValue('contact_info.social', []),
					} ?? [],
				currency: {
					code: optionsCurrency.some((c) => c.label === getCurrency())
						? getCurrency()
						: optionsCurrency[0].label ?? optionsCurrency[0].label,
					symbol: '€',
					name: 'Euro',
				},
				timezone: detailsData?.item?.timezone ?? optionsTimezone[0].value,
				store_type:
					getStoreValues('store_type') && !isEmpty(getStoreValues('store_type'))
						? [optionsCategory.find((option) => option.value === detailsData?.item?.default_store_type._id)]
						: [],
				default_store_type: detailsData?.item?.default_store_type ?? '',
				cuisine: getStoreValues('cuisine') ?? [],
				default_cuisine: getStoreValues('cuisine') ?? '',
				traits:
					{
						tags: getStoreTraitValues('tags') ?? [],
						paymentMethods: getStoreTraitValues('payment_methods') ?? [],
						suggestions: getStoreTraitValues('suggested_for') ?? [],
						environment: getStoreTraitValues('environment') ?? [],
						services: getStoreTraitValues('services') ?? [],
						misc: getStoreTraitValues('misc') ?? [],
						info: getStoreTraitValues('info') ?? [],
					} ?? [],
				reservation_prefs: getReservationValues() ?? [],
				prepayment: detailsData?.item?.prepayment ?? '',
				prepayment_policy: detailsData?.item?.prepayment_policy ?? '',
				cancelation_policy: detailsData?.item?.cancelation_policy ?? '',
				cancel_max_time_hrs: detailsData?.item?.cancel_max_time_hrs ?? 0,
				notes: detailsData?.item?.notes ?? '',
				published: detailsData?.item?.published ?? false,
				opening_hours: getOpeningHoursValues() ?? [],
				payment_rules: detailsData?.item?.payment_rules ?? [],
				online_booking_settings: {
					assign_tables: detailsData?.item?.online_booking_settings?.assign_tables ?? managingTablesOptions[0].value,
					accept_bookings: detailsData?.item?.online_booking_settings?.accept_bookings ?? acceptingBookingOptions[0].value,
					disabled: detailsData?.item?.online_booking_settings?.disabled ?? false,
					accept_waitlists: detailsData?.item?.online_booking_settings?.accept_waitlists ?? false,
					min_people: detailsData?.item?.online_booking_settings?.min_people ?? 1,
					max_people: detailsData?.item?.online_booking_settings?.max_people ?? 15,
					max_people_notice: detailsData?.item?.online_booking_settings?.max_people_notice ?? '',
					time_interval_mins: detailsData?.item?.online_booking_settings?.time_interval_mins ?? 30,
					default_duration_mins: detailsData?.item?.online_booking_settings?.default_duration_mins ?? 120,
					min_advance_time_mins: detailsData?.item?.online_booking_settings?.min_advance_time_mins ?? 0,
					max_advance_time_days: detailsData?.item?.online_booking_settings?.max_advance_time_days ?? 180,
					max_similar: detailsData?.item?.online_booking_settings?.max_similar ?? 0,
					require_prepayment: detailsData?.item?.online_booking_settings?.require_prepayment ?? false,
					refund_pct: detailsData?.item?.online_booking_settings?.refund_pct ?? 0,
					prepayment_pm_rule:
						detailsData?.item?.online_booking_settings?.prepayment_pm_rule ??
						detailsData?.item?.payment_rules.find((i) => i.type === 'prepayment')?._id ??
						'',
					charge_no_show_fee: detailsData?.item?.online_booking_settings?.charge_no_show_fee ?? false,
					no_show_pm_rule:
						detailsData?.item?.online_booking_settings?.no_show_pm_rule ??
						detailsData?.item?.payment_rules.find((i) => i.type === 'no_show_fee')?._id ??
						'',
					vat_pct: detailsData?.item?.online_booking_settings?.vat_pct ?? 0,
					translations: {
						en: {
							max_people_notice: editMode ? detailsData?.item?.online_booking_settings?.translations?.en?.max_people_notice ?? '' : '',
						},
						el: {
							max_people_notice: editMode ? detailsData?.item?.online_booking_settings?.translations?.el?.max_people_notice ?? '' : '',
						},
					},
				},
				banner: detailsData?.item?.banner ?? [],
				menu: detailsData?.item?.menu ?? [],
				media: detailsData?.item?.media ?? [],
				price_rating: detailsData?.item?.price_rating ?? '1',
				use_business_social: detailsData?.item?.use_business_social ?? setValue('use_business_social', false),
				translations: {
					en: {
						title: editMode ? detailsData?.item?.translations?.en?.title ?? detailsData?.item?.title ?? '' : '',
						description: editMode ? detailsData?.item?.translations?.en?.description ?? detailsData?.item?.description ?? '' : '',
						prepayment_policy: editMode
							? detailsData?.item?.translations?.en?.prepayment_policy ?? detailsData?.item?.prepayment_policy ?? ''
							: '',
						cancelation_policy: editMode
							? detailsData?.item?.translations?.en?.cancelation_policy ?? detailsData?.item?.cancelation_policy ?? ''
							: '',
						notes: editMode ? detailsData?.item?.translations?.en?.notes ?? detailsData?.item?.notes ?? '' : '',
						menu: editMode ? detailsData?.item?.translations?.en?.menu ?? detailsData?.item?.menu ?? [] : [],
					},
					el: {
						title: editMode ? detailsData?.item?.translations?.el?.title ?? '' : '',
						description: editMode ? detailsData?.item?.translations?.el?.description ?? '' : '',
						prepayment_policy: editMode ? detailsData?.item?.translations?.el?.prepayment_policy ?? '' : '',
						cancelation_policy: editMode ? detailsData?.item?.translations?.el?.cancelation_policy ?? '' : '',
						notes: editMode ? detailsData?.item?.translations?.el?.notes ?? '' : '',
						menu: editMode ? detailsData?.item?.translations?.el?.menu ?? [] : [],
					},
				},
			};
		}
	}, [isLoadingDetails]);

	const {
		handleSubmit,
		register,
		watch,
		reset,
		resetField,
		control,
		trigger,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onSubmit',
		defaultValues: { ...storeValues },
		shouldFocusError: true,
		resolver: yupResolver(validationSchema),
	});

	/** Watch change of each entry **/
	// console.log(watch({ ...storeValues }));
	/** Show errors captured by validation **/
	console.log(errors ? errors : 'Store information submitted successfully');

	useEffect(() => {
		if (editMode && !isLoadingDetails) {
			const formData = { ...storeValues };
			reset(formData);
		}
	}, [detailsData, editMode, isLoadingDetails, reset]);

	const [createStore] = useCreateStoreMutation();
	const [updateStore] = useEditStoreMutation();

	useImperativeHandle(ref, () => ({ onSubmit: () => handleSubmit(onSubmit)() }));

	const closeModal = () => {
		dispatch(setShowModal({ type: 'location', show: false }));
	};

	const onSubmit = async (data) => {
		const result = { ...data };
		const businessId = result.businessId ?? business_id;
		const traits = [];
		const cuisine = [];
		const storeType = [];
		const info = [];
		const reservation = [];
		/** Prepare Object for submission (Transform state and remove keys) **/

		/** Obsolete keys **/
		// Business
		if (result.business) {
			delete result.business;
		}
		if (result.payment_rules_temp) {
			delete result.payment_rules_temp;
		}
		/** Transformed Object **/
		// Traits
		if (result.traits && !isEmpty(result.traits)) {
			for (const key in result.traits) {
				if (Object.hasOwn(result.traits, key)) {
					result.traits[key].forEach((item) => traits.push(item.value));
				}
			}
		}

		// Store Type
		if (result.store_type && !isEmpty(result.store_type)) {
			result.store_type.forEach((item) => storeType.push(item.value));
		}

		// Contact Information
		if (result.contact_info && !isEmpty(result.contact_info)) {
			const details = getSocialValues();
			for (const key in result.contact_info) {
				result.contact_info[key].forEach((item) => {
					if (editMode && !result.contact_info[key].includes(item)) {
						info.push(item);
					}
					info.push(item);
				});
			}
		}

		// Reservation preferences
		if (result.reservation_prefs && !isEmpty(result.reservation_prefs)) {
			result.reservation_prefs.forEach((item) => reservation.push(item.value));
		}

		// Opening hours
		if (result.opening_hours && !isEmpty(result.opening_hours)) {
			for (const [key] of Object.entries(result.opening_hours)) {
				result.opening_hours[key].max_bookings =
					result.opening_hours[key].max_bookings === 'Unlimited' ? 0 : parseInt(result.opening_hours[key].max_bookings);
				result.opening_hours[key].max_guests =
					result.opening_hours[key].max_guests === 'Unlimited' ? 0 : parseInt(result.opening_hours[key].max_guests);
				result.opening_hours[key].max_interval_bookings =
					result.opening_hours[key].max_interval_bookings === 'Unlimited' ? 0 : parseInt(result.opening_hours[key].max_interval_bookings);
				result.opening_hours[key].max_interval_guests =
					result.opening_hours[key].max_interval_guests === 'Unlimited' ? 0 : parseInt(result.opening_hours[key].max_interval_guests);
				if (
					result.opening_hours[key]?.translations?.en?.notes !== '' &&
					result.opening_hours[key]?.notes !== result.opening_hours[key]?.translations?.en?.notes
				) {
					result.opening_hours[key].notes = result.opening_hours[key]?.translations?.en?.notes;
				}
				delete result.opening_hours[key].day;
				delete result.opening_hours[key].time;
				delete result.opening_hours[key]._id;
			}
		}

		// Payment Rules
		if (result.payment_rules && !isEmpty(result.payment_rules)) {
			result.payment_rules.forEach((i, index) => {
				if (i.min_prepayment_guests === 'Always (1)') {
					result.payment_rules[index].min_prepayment_guests = 1;
				} else {
					result.payment_rules[index].min_prepayment_guests = i.min_prepayment_guests;
				}
				i.rule_type = 'per_booking';
				i.charge_type = 'pre_payment';
				i.title = i.translations.en.title;
				i.terms = i.translations.en.terms;
				detailsData?.payment_rules && detailsData?.payment_rules.length > 0
					? detailsData.find((item) => item._id !== i._id)
						? delete i._id
						: delete result.payment_rules[index]._id
					: delete i._id;
			});
			delete result.payment_rules_temp;
		} else result.payment_rules = [];

		if (result.store_type && !isEmpty(result.store_type)) {
			// Default
			if (result.default_store_type === '') {
				result.default_store_type = result.store_type[0]._id;
			}
			delete result.default_store_type;
		} else delete result.default_store_type;

		// Cuisine
		if (result.cuisine && !isEmpty(result.cuisine)) {
			// Default
			if (result.default_cuisine === '') {
				result.default_cuisine = result.cuisine[0]._id;
			}
			delete result.default_cuisine;
		} else delete result.default_cuisine;

		// Online booking settings
		if (Object.keys(result.online_booking_settings).length >= 1) {
			result.online_booking_settings.max_people = parseInt(result.online_booking_settings.max_people ?? 15);
			result.online_booking_settings.min_people = parseInt(result.online_booking_settings.min_people ?? 1);
			result.online_booking_settings.max_advance_time_days = parseInt(result.online_booking_settings.max_advance_time_days ?? 1);
			result.online_booking_settings.min_advance_time_mins = parseInt(result.online_booking_settings.min_advance_time_mins ?? 1);
			result.online_booking_settings.time_interval_mins = parseInt(result.online_booking_settings.time_interval_mins);
			result.online_booking_settings.default_duration_mins = parseInt(result.online_booking_settings.default_duration_mins) ?? 120;
			result.online_booking_settings.max_similar = parseInt(result.online_booking_settings.max_similar) ?? 10;
			result.online_booking_settings.refund_pct = parseInt(result.online_booking_settings.refund_pct ?? 0);
			result.online_booking_settings.vat_pct = parseInt(result.online_booking_settings.vat_pct ?? 0);
			result.online_booking_settings.max_people_notice = result.online_booking_settings.max_people_notice ?? '';
		} else delete result.online_booking_settings;
		result.traits = traits;
		result.cuisine = cuisine;
		result.contact_info = info;
		result.reservation_prefs = reservation;
		result.store_type = storeType;
		result.published = false;
		result.currency = { code: 'EUR', symbol: '€', name: 'Euro' };
		result.online_booking_settings.assign_tables = result.online_booking_settings.assign_tables ?? 'auto';
		result.online_booking_settings.accept_bookings = result.online_booking_settings.accept_bookings ?? 'manual';
		result.phone = `${result['country_code']}${result.phone}`;

		// English translations to parent keys
		result.title = result?.translations?.en?.title ?? result?.title;
		result.description = result?.description ?? result?.translations?.en?.description;
		result.prepayment_policy = result?.prepayment_policy ?? result?.translations?.en?.prepayment_policy;
		result.cancelation_policy = result?.cancelation_policy ?? result?.translations?.en?.cancelation_policy;
		result.notes = result?.notes ?? result?.translations?.en?.notes;
		result.menu = result?.menu ?? result?.translations?.en?.menu;
		result.online_booking_settings.title = result.online_booking_settings?.translations?.en?.title;
		result.online_booking_settings.terms = result.online_booking_settings?.translations?.en?.terms;

		// Submission
		if (Object.keys(errors).length === 0) {
			editMode
				? await updateStore({ bid: businessId ?? business_id, path: { id: id }, body: { ...result } })
				: await createStore({ bid: businessId ?? business_id, path: { id: id }, body: { ...result } });
			delete result.businessId;
			closeModal();
		}
	};

	return (
		<>
			<ul className="nav nav-tabs nav-line-tabs d-flex justify-content-center mb-5 fs-3">
				<li className="nav-item">
					<a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_details" name="details">
						<FormattedMessage id="STORE.MODAL.DETAILS" />
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" data-bs-toggle="tab" href="#kt_tab_opening_hours" name="opening_hours">
						<FormattedMessage id="STORE.MODAL.OPENING_HOURS" />
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" data-bs-toggle="tab" href="#kt_tab_settings" name="settings">
						<FormattedMessage id="STORE.MODAL.SETTINGS" />
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" data-bs-toggle="tab" href="#kt_tab_payments" name="payments">
						<FormattedMessage id="STORE.MODAL.PAYMENTS" />
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" data-bs-toggle="tab" href="#kt_tab_files" name="files">
						<FormattedMessage id="STORE.MODAL.FILES" />
					</a>
				</li>
			</ul>

			<div className="tab-content" id="myTabContent">
				<div className="tab-pane fade active show" id="kt_tab_details" role="tabpanel">
					<TabDetails
						register={register}
						control={control}
						watch={watch}
						errors={errors}
						trigger={trigger}
						setValue={setValue}
						reset={reset}
					/>
				</div>
				<div className="tab-pane fade" id="kt_tab_opening_hours" role="tabpanel">
					<TabOpeningHours register={register} resetField={resetField} control={control} watch={watch} trigger={trigger} errors={errors} />
				</div>
				<div className="tab-pane fade" id="kt_tab_settings" role="tabpanel">
					<TabSettings register={register} control={control} watch={watch} errors={errors} />
				</div>
				<div className="tab-pane fade" id="kt_tab_payments" role="tabpanel">
					<TabPayments
						register={register}
						control={control}
						watch={watch}
						errors={errors}
						trigger={trigger}
						reset={reset}
						setValue={setValue}
					/>
				</div>
				<div className="tab-pane fade" id="kt_tab_files" role="tabpanel">
					<TabFiles register={register} control={control} watch={watch} errors={errors} setValue={setValue} />
				</div>
			</div>
		</>
	);
};

export default StoreModalBody;
