import clsx from 'clsx';
import { Modal as RBModal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { areModalFormsActive } from 'src/reducers/modalFormSlice';

import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import useModal from './useModal';

const Modal = () => {
	const { maxWidth, title, onClose, border } = useModal();
	const modalsActive = useSelector(areModalFormsActive);
	return (
		<RBModal
			id={`kt_modal_${title}`}
			tabIndex={-1}
			aria-hidden
			dialogClassName={`modal-dialog modal-dialog-centered ${maxWidth}`}
			show
			onHide={onClose}
			backdrop
		>
			<ModalHeader />
			<ModalBody border />
			<ModalFooter />
		</RBModal>
	);
};

export default Modal;
