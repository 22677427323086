import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { KTSVG } from '../../_metronic/helpers';
import File from './File';

type Props = {
	control: Control<any>;
	name: string;
	disabled?: boolean;
	errors?: any;
};

const FileControl: React.FC<Props> = ({ control, name, disabled = false, errors = {} }) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={[]}
			render={({ field: { value, onChange } }) => (
				<>
					{value.map((media) => (
						<div
							key={media._id}
							className="d-inline-flex align-items-center uploader-container p-4 fs-5 fw-bold"
							onClick={() => {
								value = value.filter(({ _id }) => _id !== media._id);
								onChange(value);
							}}
						>
							<span className="fs-7 text-primary">{media.title}</span>
							<KTSVG className="svg-icon-2x svg-icon-primary px-2" path="/media/icons/duotune/general/gen040.svg" />
						</div>
					))}
					<File
						disabled={disabled}
						onUpload={(media) => {
							value.push(media);
							onChange(value);
						}}
					/>
					{errors && (
						<ErrorMessage
							errors={errors}
							name={name}
							render={({ message }) => (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{message}</span>
									</div>
								</div>
							)}
						/>
					)}
				</>
			)}
		/>
	);
};

export default FileControl;
