import clsx from 'clsx';
import { useState } from 'react';

import { BUSINESS_LANGUAGES } from '../../../../common/constants';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';

const ReservationForm = () => {
	const [guestDtlsLang, setGuestDtlsLang] = useState(BUSINESS_LANGUAGES[0].code);
	const [teamNotesLang, setTeamNotesLang] = useState(BUSINESS_LANGUAGES[0].code);
	const [msgGuestLang, setMsgGuestLang] = useState(BUSINESS_LANGUAGES[0].code);

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1">
				<div className="row">
					<div className="col-12">
						<h2 className="my-5 py-5">Reservation Type</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-sm-6">
						<a className="action-container d-block checked p-5 fs-5 fw-bold">
							<div className="checkbox-wrapper">
								<input type="checkbox" id="checkbox1" className="custom-checkbox" name="checkbox1" defaultChecked />
								<label htmlFor="checkbox1" className="checkbox-label">
									<span>Reservation</span>
								</label>
							</div>
						</a>
					</div>
					<div className="col-12 col-sm-6">
						<a className="action-container d-block p-5 fs-5 fw-bold">
							<div className="checkbox-wrapper">
								<input type="checkbox" id="checkbox2" className="custom-checkbox" name="checkbox2" />
								<label htmlFor="checkbox2" className="checkbox-label">
									<span>Event</span>
								</label>
							</div>
						</a>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h2 className="my-5 py-5">Reservation Details</h2>
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-12 col-sm-6">
						<div className="pb-3 fs-6 fw-bold">Date</div>
						<div className="custom-select">
							<div className="custom-select-icon icon-left">
								<KTSVG path="/media/icons/duotune/general/gen014.svg" className="svg-icon-muted svg-icon-2" />
							</div>
							<div className="select-wrapper">
								<div className="select-header icon-left p-3">
									<span className="selected-option">5 June 2023</span>
									<i className="fa-solid fa-chevron-down arrow-icon" />
								</div>
								<ul className="select-options d-none">
									<li className="option">5 June 2023</li>
									<li className="option">6 June 2023</li>
									<li className="option">7 June 2023</li>
								</ul>
							</div>
							<select className="hidden-select">
								<option value="option1">5 June 2023</option>
								<option value="option2">6 June 2023</option>
								<option value="option3">7 June 2023</option>
							</select>
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="pb-3 fs-6 fw-bold">Time</div>
						<div className="custom-select">
							<div className="custom-select-icon icon-left">
								<KTSVG path="/media/icons/duotune/general/gen013.svg" className="svg-icon-muted svg-icon-2" />
							</div>
							<div className="select-wrapper">
								<div className="select-header icon-left p-3">
									<span className="selected-option">19:00</span>
									<i className="fa-solid fa-chevron-down arrow-icon" />
								</div>
								<ul className="select-options d-none">
									<li className="option">19:00</li>
									<li className="option">19:30</li>
									<li className="option">20:00</li>
								</ul>
							</div>
							<select className="hidden-select">
								<option value="option1">19:00</option>
								<option value="option2">19:30</option>
								<option value="option3">20:00</option>
							</select>
						</div>
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-12 col-sm-6">
						<div className="pb-3 fs-6 fw-bold">Store</div>
						<div className="custom-select">
							<div className="select-wrapper">
								<div className="select-header p-3">
									<span className="selected-option">Kifisia</span>
									<i className="fa-solid fa-chevron-down arrow-icon" />
								</div>
								<ul className="select-options d-none">
									<li className="option">Kifisia</li>
									<li className="option">Nea Smyrni</li>
									<li className="option">Athens</li>
								</ul>
							</div>
							<select className="hidden-select">
								<option value="option1">Kifisia</option>
								<option value="option2">Nea Smyrni</option>
								<option value="option3">Athens</option>
							</select>
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="pb-3 fs-6 fw-bold">
							Party Size<sup>*</sup>
						</div>
						<div className="custom-select">
							<div className="select-wrapper">
								<div className="select-header p-3">
									<span className="selected-option">6 People</span>
									<i className="fa-solid fa-chevron-down arrow-icon" />
								</div>
								<ul className="select-options d-none">
									<li className="option">6 People</li>
									<li className="option">7 People</li>
									<li className="option">8 People</li>
								</ul>
							</div>
							<select className="hidden-select">
								<option value="option1">6 People</option>
								<option value="option2">7 People</option>
								<option value="option3">8 People</option>
							</select>
						</div>
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-md-4">
						<div className="pb-3 fs-6 fw-bold">Source</div>
						<div className="custom-select">
							<div className="select-wrapper">
								<div className="select-header p-3">
									<span className="selected-option">Online</span>
									<i className="fa-solid fa-chevron-down arrow-icon" />
								</div>
								<ul className="select-options d-none">
									<li className="option">Online</li>
									<li className="option">Via Phone</li>
									<li className="option">Via App</li>
								</ul>
							</div>
							<select className="hidden-select">
								<option value="option1">Online</option>
								<option value="option2">Via Phone</option>
								<option value="option3">Via App</option>
							</select>
						</div>
					</div>
					<div className="col-md-4">
						<div className="pb-3 fs-6 fw-bold">
							Status<sup>*</sup>
						</div>
						<div className="custom-select">
							<div className="select-wrapper">
								<div className="select-header p-3">
									<span className="selected-option">Accepted</span>
									<i className="fa-solid fa-chevron-down arrow-icon" />
								</div>
								<ul className="select-options d-none">
									<li className="option">Accepted</li>
									<li className="option">Pending</li>
									<li className="option">Declined</li>
								</ul>
							</div>
							<select className="hidden-select">
								<option value="option1">Accepted</option>
								<option value="option2">Pending</option>
								<option value="option3">Declined</option>
							</select>
						</div>
					</div>
					<div className="col-md-4">
						<div className="pb-3 fs-6 fw-bold">Duration</div>
						<div className="custom-select">
							<div className="select-wrapper">
								<div className="select-header p-3">
									<span className="selected-option">1:30</span>
									<i className="fa-solid fa-chevron-down arrow-icon" />
								</div>
								<ul className="select-options d-none">
									<li className="option">1:30</li>
									<li className="option">2:00</li>
									<li className="option">2:30</li>
								</ul>
							</div>
							<select className="hidden-select">
								<option value="option1">1:30</option>
								<option value="option2">2:00</option>
								<option value="option3">2:30</option>
							</select>
						</div>
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-12">
						<div className="input-group">
							<label htmlFor="guest-email" className="form-label pb-3 mb-0 fs-6 fw-bold">
								Preferences
							</label>
							<input type="text" className="form-control p-4" data-role="tagsinput" />
						</div>
					</div>
				</div>
				<div className="container p-5 generic-border">
					<div className="row">
						<div className="col-12">
							<div className="row pb-5">
								<div className="col-12 col-sm-4">
									<div className="fs-6 fw-bold">Assigned Table</div>
								</div>
							</div>
							<div className="row pb-5">
								<div className="col-12 col-sm-4">
									<div className="custom-select">
										<div className="select-wrapper">
											<div className="select-header p-3">
												<span className="selected-option">9 (4) - Main Dining</span>
												<i className="fa-solid fa-chevron-down arrow-icon" />
											</div>
											<ul className="select-options d-none">
												<li className="option">9 (4) - Main Dining</li>
												<li className="option">9 (4) - Main Dining</li>
												<li className="option">9 (4) - Main Dining</li>
											</ul>
										</div>
										<select className="hidden-select">
											<option value="option1">9 (4) - Main Dining</option>
											<option value="option2">9 (4) - Main Dining</option>
											<option value="option3">9 (4) - Main Dining</option>
										</select>
									</div>
								</div>
								<div className="col-12 col-sm-4">
									<button type="button" className="generic-button h-100 w-100 fw-bold">
										Merge with another table
									</button>
								</div>
							</div>
							<div className="row pb-5">
								<div className="col-12 col-sm-4">
									<div className="fs-6 fw-bold">Assigned Table</div>
								</div>
							</div>
							<div className="row pb-5">
								<div className="col-12 col-sm-4">
									<div className="custom-select">
										<div className="select-wrapper">
											<div className="select-header icon-right p-3">
												<span className="selected-option">9 (4) - Main Dining</span>
												<i className="fa-solid fa-chevron-down arrow-icon" />
											</div>
											<ul className="select-options d-none">
												<li className="option">9 (4) - Main Dining</li>
												<li className="option">9 (4) - Main Dining</li>
												<li className="option">9 (4) - Main Dining</li>
											</ul>
										</div>
										<select className="hidden-select">
											<option value="option1">9 (4) - Main Dining</option>
											<option value="option2">9 (4) - Main Dining</option>
											<option value="option3">9 (4) - Main Dining</option>
										</select>
										<a href="#" className="custom-select-icon icon-right">
											<i className="fa-solid fa-trash-can text-danger" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="my-5 horizontal-separator" />
				<div className="row">
					<div className="col-12">
						<h2 className="pb-5 mb-0">Guest Details</h2>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="input-group guest-search">
							<i className="fa-solid fa-magnifying-glass search-icon" />
							<input type="text" className="form-control p-4" data-role="tagsinput" placeholder="Search Guest by Name or Phone" />
						</div>
					</div>
				</div>
				<div className="container my-5 p-3 generic-border">
					<div className="row py-3">
						<div className="col-12">
							{BUSINESS_LANGUAGES.map((bl) => (
								<span
									key={bl.code}
									id={bl.code}
									className={clsx('flag-image-container p-3', { active: guestDtlsLang === bl.code })}
									onClick={(e) => setGuestDtlsLang(e.currentTarget.id)}>
									<img className="flag-image rounded-circle" src={toAbsoluteUrl(bl.flag)} alt={bl.name} />
									<span className="fs-4">{bl.abbr}</span>
								</span>
							))}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="input-group">
								<label htmlFor="guest-email" className="form-label py-3 mb-0 fs-6 fw-bold">
									FullName<sup>*</sup>
								</label>
								<input type="text" className="form-control p-4" placeholder="Nikos Doukas" data-np-autofill-type="fullName" />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col-12 col-sm-6">
								<div className="input-group">
									<label htmlFor="guest-email" className="form-label pb-3 mb-0 fs-6 fw-bold">
										Email<sup>*</sup>
									</label>
									<input
										type="email"
										className="form-control p-4"
										id="guest-email"
										aria-describedby="emailHelp"
										placeholder="E-mail"
									/>
								</div>
							</div>
							<div className="col-12 col-sm-2">
								<div className="pb-3 mb-0 fs-6 fw-bold">
									Phone Number<sup>*</sup>
								</div>
								<div className="custom-select">
									<div className="select-wrapper">
										<div className="select-header p-4">
											<span className="selected-option">+30</span>
											<i className="fa-solid fa-chevron-down arrow-icon" />
										</div>
										<ul className="select-options d-none">
											<li className="option">+30</li>
											<li className="option">+30</li>
											<li className="option">+30</li>
										</ul>
									</div>
									<select className="hidden-select">
										<option value="option1">+30</option>
										<option value="option1">+30</option>
										<option value="option1">+30</option>
									</select>
								</div>
							</div>
							<div className="col-12 col-sm-4">
								<div className="input-group">
									<label htmlFor="guest-phone" className="form-label pb-3 mb-0 fs-6 fw-bold">
										&nbsp;
									</label>
									<input
										type="phone"
										className="form-control p-4"
										id="guest-phone"
										aria-describedby="phoneHelp"
										placeholder="Label"
									/>
								</div>
							</div>
						</div>
						<div className="row pt-5">
							<div className="col-12">
								<div className="form-check form-check-custom form-check-solid">
									<input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked />
									<label className="form-check-label" htmlFor="flexCheckChecked">
										Send Email notifications to guest
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="my-5 horizontal-separator" />
				<div className="row">
					<div className="col-12">
						<h2 className="pb-5 mb-0">Other Guests (Optional)</h2>
					</div>
				</div>
				<button type="button" className="generic-button fw-bold">
					+ Add Guest
				</button>

				{/* <button type="button" className="btn btn-sm btn-light-primary btn-color-primary d-flex align-items-center mt-2">
                    <div>+ Add Guest</div>
                </button> */}
				<div className="my-5 horizontal-separator" />
				<div className="row">
					<div className="col-12">
						<h2 className="py-5">Team Notes</h2>
					</div>
				</div>
				<div className="container my-5 p-3 generic-border">
					<div className="row py-5">
						<div className="col-12">
							{BUSINESS_LANGUAGES.map((bl) => (
								<span
									key={bl.code}
									id={bl.code}
									className={clsx('flag-image-container p-3', { active: teamNotesLang === bl.code })}
									onClick={(e) => setTeamNotesLang(e.currentTarget.id)}>
									<img className="flag-image rounded-circle" src={toAbsoluteUrl(bl.flag)} alt={bl.name} />
									<span className="fs-4">{bl.abbr}</span>
								</span>
							))}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<textarea className="full-width-textarea" rows="4" cols="50" />
						</div>
					</div>
				</div>
				<div className="my-5 horizontal-separator" />
				<div className="row">
					<div className="col-12">
						<h2 className="py-5">Message to Guest</h2>
					</div>
				</div>
				<div className="container my-5 p-3 generic-border">
					<div className="row py-5">
						<div className="col-12">
							{BUSINESS_LANGUAGES.map((bl) => (
								<span
									key={bl.code}
									id={bl.code}
									className={clsx('flag-image-container p-3', { active: msgGuestLang === bl.code })}
									onClick={(e) => setMsgGuestLang(e.currentTarget.id)}>
									<img className="flag-image rounded-circle" src={toAbsoluteUrl(bl.flag)} alt={bl.name} />
									<span className="fs-4">{bl.abbr}</span>
								</span>
							))}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="py-3 fs-6 fw-bold">Message</div>
							<textarea className="full-width-textarea" rows="4" cols="50" />
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export { ReservationForm };
