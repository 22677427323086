import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
// import { useDispatch } from 'react-redux';
import useModal from 'src/components/modal/useModal';
import useTableWithItems from 'src/components/table/useTableWithItems';

// import { setShowModal } from '../../../../reducers/modalSlice';
import PaymentsFormModal from './paymentsComponents/PaymentsFormModal';
import PaymentsTable from './paymentsComponents/PaymentsTable';
import { paymentRuleSelectOptions } from './settingsComponents/settingsOptions';

const TabPayments = (props) => {
	const { register, reset, control, watch, trigger, setValue } = props;
	// const dispatch = useDispatch();
	const rules = watch('payment_rules') ?? [];
	const rulesTemp = watch('payment_rules_temp');
	const prepaymentRules = rules && rules.filter((rule) => rule.type === 'prepayment');
	const noShowFeeRules = rules && rules.filter((rule) => rule.type === 'no_show_fee');
	const [paymentData, setPaymentData] = useState([]);
	const [openDropdown, setOpenDropdown] = useState('');
	const [showInputFields, setShowInputFields] = useState(false);
	const [isEditMode, setEditMode] = useState(false);
	const initialisePaymentState = () => {
		setEditMode(false);
		setPaymentData([]);
		setShowInputFields(false);
	};

	const handleEdit = (id) => {
		const editItem = rules.find((item) => item._id === id);
		setPaymentData(editItem);
		setEditMode(true);
		setShowInputFields(false);
		setTimeout(() => {
			setShowInputFields(true);
			// dispatch(setShowModal({ type: 'locationPayments' }));
		}, 100);
	};

	const handleDelete = (id) => {
		const rule = rules.filter((i) => i._id === id);
		if (rule) {
			rules.forEach((item, index) => {
				if (item._id === id) {
					rules.splice(index, 1);
				}
			});
			trigger(['payment_rules']);
			initialisePaymentState();
		}
	};

	const handleAddPayment = () => {
		initialisePaymentState();
		setShowInputFields(true);
	};

	const handleCancelButton = () => {
		initialisePaymentState();
	};

	const createNewRule = () => {
		if (!isEditMode) {
			setValue('payment_rules', [...rules, ...rulesTemp]);
			initialisePaymentState();
		}
		rules[rules.findIndex((i) => i._id === paymentData._id)] = paymentData;
		setShowInputFields(false);
	};

	const ruleTypeRef = useRef();
	const chargeTypeRef = useRef();
	const autoCancelRef = useRef();
	const minGuestsRef = useRef();

	const dropdownRefs = [ruleTypeRef, chargeTypeRef, autoCancelRef, minGuestsRef];

	useEffect(() => {
		const handleClick = (ev) => {
			const isClickInsideDropdown = dropdownRefs.some((menuRef) => menuRef.current && menuRef.current.contains(ev.target));
			const isClickInsideAnOpenDropdown = openDropdown !== '';

			if (!isClickInsideDropdown && isClickInsideAnOpenDropdown) {
				setOpenDropdown('');
			}
		};

		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [dropdownRefs, openDropdown]);
	const { ref } = useModal();
	const tableValues = useMemo(() => {
		return {
			title: 'Payment Rules',
			items: rules,
			totalRows: rules.length,
			content: <PaymentsTable onEditPayment={handleEdit} onDeletePayment={handleDelete} />,
		};
	});

	const { table } = useTableWithItems(tableValues);

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1" ref={ref}>
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							{showInputFields && !isEditMode ? (
								<FormattedMessage id="LOCATION.MODAL.CREATE.PAYMENT_RULES.TITLE" />
							) : showInputFields && isEditMode ? (
								<FormattedMessage id="LOCATION.MODAL.EDIT.PAYMENT_RULES.TITLE" />
							) : (
								<FormattedMessage id="LOCATION.PAYMENTS_TAB.HEADER" />
							)}
						</div>
					</div>
				</div>

				{!showInputFields ? table : null}

				{!showInputFields ? (
					<>
						<div className="row">
							<div className="col-12" />
						</div>
						<div className="row">
							<div className="col-12 col-sm-4">
								<button type="button" className="generic-button fw-bold fs-7 p-4 w-100" onClick={handleAddPayment}>
									<FormattedMessage id="LOCATION.PAYMENTS_TAB.ADD_PAYMENT" />
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="separator my-5" />
							</div>
						</div>

						<div className="row pb-5">
							<div className="col-12">
								<div className="mb-0 fs-2 fw-bold">
									<FormattedMessage id="LOCATION.SETTINGS.PREPAYMENTS" />
								</div>
							</div>
						</div>

						<div className="row pb-5">
							<div className="col-12">
								<div className="form-check form-switch form-check-custom form-check-solid">
									<input
										className="form-check-input"
										type="checkbox"
										id="rerquire-prepayment"
										name="rerquire-prepayment"
										aria-labelledby="rerquire-prepayment"
										{...register('online_booking_settings.require_prepayment')}
									/>
									<label className="form-check-label fs-6 fw-bold" htmlFor="rerquire-prepayment">
										<FormattedMessage id="LOCATION.SETTINGS.REQUIRE_PREPAYMENT" />
									</label>
								</div>
							</div>
						</div>

						<div className="row">
							<Controller
								name="online_booking_settings.payment_rules"
								control={control}
								render={({ field }) => (
									<div className="col-12">
										<div className="pb-3 fs-7 fw-bold">
											<FormattedMessage id="LOCATION.SETTINGS.PAYMENT_RULE" />
										</div>

										{prepaymentRules ? (
											<select
												className="form-select form-select-solid text-capitalize"
												aria-label="Select example"
												{...register('online_booking_settings.prepayment_pm_rule')}
											>
												{prepaymentRules.map((rule, index) => (
													<option value={rule._id} key={index} disabled={rule.length === 0}>
														{rule.translations?.en?.title ?? rule.title}
													</option>
												))}
											</select>
										) : (
											<select name="payment.rules.prepayment" id="payment.rules.prepayment" disabled>
												<option value={paymentRuleSelectOptions[0].value}>{paymentRuleSelectOptions[0].label}</option>
											</select>
										)}
									</div>
								)}
							/>
						</div>

						<div className="row pt-5">
							<div className="input-group">
								<label htmlFor="refund-percentage" className="form-label pb-3 mb-0 fs-7 fw-bold">
									<FormattedMessage id="LOCATION.SETTINGS.REFUND" />
								</label>
								<input
									type="text"
									className="form-control fs-6 p-4"
									id="refund-percentage"
									name="refund-percentage"
									aria-labelledby="refund-percentage"
									placeholder="0"
									defaultValue="0"
									{...register('online_booking_settings.refund_pct')}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="separator my-5" />
							</div>
						</div>

						<div className="row pb-5">
							<div className="col-12">
								<div className="form-check form-switch form-check-custom form-check-solid">
									<input
										className="form-check-input"
										type="checkbox"
										id="cahrge-no-show-fee"
										name="cahrge-no-show-fee"
										aria-labelledby="cahrge-no-show-fee"
										{...register('online_booking_settings.charge_no_show_fee')}
									/>
									<label className="form-check-label fs-6 fw-bold" htmlFor="cahrge-no-show-fee">
										<FormattedMessage id="LOCATION.SETTINGS.CHARGE_NOSHOW_FEE" />
									</label>
								</div>
							</div>
						</div>

						<div className="row pb-5">
							<Controller
								name="online_booking_settings.no_show_pm_rule"
								control={control}
								render={({ field }) => (
									<div className="col-12">
										<div className="pb-3 fs-7 fw-bold">
											<FormattedMessage id="LOCATION.SETTINGS.PAYMENT_RULE" />
										</div>
										{noShowFeeRules ? (
											<select
												className="form-select form-select-solid text-capitalize"
												aria-label="Select example"
												{...register('online_booking_settings.no_show_pm_rule')}
											>
												{noShowFeeRules.map((rule, index) => (
													<option value={rule._id} key={index}>
														{rule.translations?.en?.title ?? rule.title}
													</option>
												))}
											</select>
										) : (
											<select name="payment.rules.noShowFee" id="payment.rules.noShowFee" disabled>
												<option value={paymentRuleSelectOptions[0].value}>{paymentRuleSelectOptions[0].label}</option>
											</select>
										)}
									</div>
								)}
							/>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="input-group">
									<label htmlFor="vat-pecentage" className="form-label pb-3 mb-0 fs-7 fw-bold">
										<FormattedMessage id="LOCATION.SETTINGS.VAT" />
									</label>
									<input
										type="text"
										id="vat-pecentage"
										name="vat-pecentage"
										aria-labelledby="vat-pecentage"
										className="form-control fs-6 p-4"
										data-role="tagsinput"
										placeholder="VAT percentage"
										defaultValue="0"
										{...register('online_booking_settings.vat_pct')}
									/>
									<p className="mb-0 pt-2 fs-7 secondary-text">
										<FormattedMessage id="LOCATION.SETTINGS.VAT_TXT" />
									</p>
								</div>
							</div>
						</div>
					</>
				) : null}

				{showInputFields ? (
					<PaymentsFormModal
						register={register}
						control={control}
						reset={reset}
						watch={watch}
						paymentData={paymentData}
						setPaymentData={setPaymentData}
						handleCancelButton={handleCancelButton}
						isEditMode={isEditMode}
						setValue={setValue}
						createNewRule={createNewRule}
					/>
				) : null}
			</form>
		</div>
	);
};

export default TabPayments;
