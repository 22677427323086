interface options {
	value: string;
	label: string;
}

export enum DAYS {
	mon = 'Monday',
	tue = 'Tuesday',
	wed = 'Wednesday',
	thu = 'Thursday',
	fri = 'Friday',
	sat = 'Saturday',
	sun = 'Sunday',
}

export const getCountOptions = (count: number): options[] => [
	{ value: '0', label: 'Unlimited' },
	...Array.from({ length: count }, (_, i) => {
		const value = (i + 1).toString();
		return { value, label: value };
	}),
];

export const getTimeIntervalOptions = (startTime: string, endTime: string, pace: number): options[] => {
	const timeOptions: options[] = [];
	let currentTime = parseTime(startTime);

	while (formatTime(currentTime) !== endTime) {
		timeOptions.push({ value: convertTimeToMin(formatTime(currentTime)), label: formatTime(currentTime) });
		currentTime.setMinutes(currentTime.getMinutes() + pace);
	}
	timeOptions.push({ value: convertTimeToMin(endTime), label: endTime });

	return timeOptions;
};

const parseTime = (time: string) => {
	const [hours, minutes] = time.split(':').map((part) => parseInt(part));
	const date = new Date();
	date.setHours(hours);
	date.setMinutes(minutes);
	return date;
};

const formatTime = (date: Date) => {
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	return `${hours}:${minutes}`;
};

export const convertTimeToMin = (time: string) => {
	if (time) {
		const part = time.split(':');
		return `${Number(part[0]) * 60 + Number(part[1])}`;
	}
	return '';
};

export const convertMinutesToTime = (minutes: number) => {
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;
	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(remainingMinutes).padStart(2, '0');
	return `${formattedHours}:${formattedMinutes}`;
};

export const formatDays = (days) => {
	if (days < 7) {
		return `${days} day${days > 1 ? 's' : ''}`;
	} else if (days % 7 === 0 && days < 30) {
		const weeks = days / 7;
		return `${weeks} week${weeks > 1 ? 's' : ''}`;
	} else if (days % 30 === 0) {
		const months = days / 30;
		return `${months} month${months > 1 ? 's' : ''}`;
	} else if (days < 30) {
		const weeks = Math.floor(days / 7);
		const remainingDays = days % 7;
		return `${weeks} week${weeks > 1 ? 's' : ''} ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
	} else {
		const months = Math.floor(days / 30);
		const remainingDays = days % 30;
		return `${months} month${months > 1 ? 's' : ''} ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
	}
};
