import React from 'react';
import { FormattedMessage } from 'react-intl';

import LocationBookingGuestsDropdown from './LocationBookingGuestsDropdowns';
import LocationDayHoursDropdowns from './LocationDayHoursDropdowns';

const OpeningHoursFormModal = ({ register, control, day, watch, updateSelectedOptions, tempSelectedOptions, handleCancelButton }) => {
	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<LocationDayHoursDropdowns register={register} control={control} watch={watch} day={day} setDay={day} />
					<LocationBookingGuestsDropdown register={register} control={control} watch={watch} day={day} />
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="d-flex justify-content-end">
						<button
							type="submit"
							className="generic-button fs-6 fw-bold mx-5"
							onClick={updateSelectedOptions}
							disabled={tempSelectedOptions.selectedDay === ''}
						>
							<FormattedMessage id="GENERAL.ADD" />
						</button>

						<button type="button" onClick={handleCancelButton} className="delete-button fs-6 fw-bold">
							<FormattedMessage id="GENERAL.CANCEL" />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default OpeningHoursFormModal;
