import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useGetTranslationsQuery, useLazyGetTranslationsQuery } from 'src/app/services/apiSlice';
import { useGetAccountQuery } from 'src/reducers/settingsApiSlice';
import * as Yup from 'yup';

import { getSessionStorage } from '../../../common/storage';
import useLoginMutation from '../../../reducers/auth/authApiSlice';

const loginSchema = Yup.object().shape({
	email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
	password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
});

const Login = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const pathname = location.state?.from?.pathname;

	const [login, { isSuccess, isLoading, isError }] = useLoginMutation();
	const [getTranslations] = useLazyGetTranslationsQuery(); //  { skip: !shouldFetchTranslations() }

	useEffect(() => {
		const getTrans = async () => {
			await getTranslations();
		};

		if (isSuccess) {
			getTrans();
			const from = pathname || '/dashboard';
			navigate(from, { replace: true });

			document.body.classList.remove('app-auth-default');
		}
	}, [isSuccess, navigate, pathname, dispatch]);

	const onSubmit = ({ email, password }) => {
		const args = {
			email,
			password,
		};

		login(args);
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting, touchedFields },
	} = useForm({
		mode: 'all',
		resolver: yupResolver(loginSchema),
	});

	return (
		<div className="card card-custom overlay min-h-125px shadow-none">
			<div className="card-body d-flex flex-column pt-10">
				<form className="form w-100" onSubmit={handleSubmit(onSubmit)} noValidate id="kt_login_signin_form">
					<div className="text-center mb-11">
						<h1 className="text-dark fw-bolder mb-3">
							Sign In
							{/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
						</h1>
					</div>

					{isError && (
						<div className="mb-lg-15 alert alert-danger">
							<div className="alert-text font-weight-bold">
								<FormattedMessage id="AUTH.LOGIN.ERROR" />
							</div>
						</div>
					)}

					<div className="fv-row mb-8">
						<label className="form-label fs-6 fw-bolder text-dark">
							Email
							{/* <FormattedMessage id="AUTH.INPUT.EMAIL" /> */}
						</label>
						<input
							type="email"
							placeholder="Email"
							{...register('email')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': touchedFields.email && errors?.email },
								{ 'is-valid': touchedFields.email && !errors?.email }
							)}
							autoComplete="off"
						/>
						{errors?.email && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.email.message}</span>
								</div>
							</div>
						)}
					</div>

					<div className="fv-row mb-3">
						<label className="form-label fw-bolder text-dark fs-6 mb-0">
							Password
							{/* <FormattedMessage id="AUTH.INPUT.PASSWORD" /> */}
						</label>
						<input
							type="password"
							{...register('password')}
							className={clsx(
								'form-control bg-transparent',
								{ 'is-invalid': touchedFields.password && errors.password },
								{ 'is-valid': touchedFields.password && !errors.password }
							)}
							autoComplete="off"
							placeholder="********"
						/>
						{errors?.password && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.password.message}</span>
								</div>
							</div>
						)}
					</div>

					{/* <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
						<div />

						<Link to="/auth/forgot-password" className="link-primary">
							Forgot Password ?
						</Link>
					</div> */}
					<div className="d-grid mb-10">
						<button type="submit" id="kt_sign_in_submit" className="btn btn-primary" disabled={isSubmitting || !isValid}>
							{!isLoading && (
								<span className="indicator-label">
									Continue
									{/* <FormattedMessage id="AUTH.LOGIN.BUTTON" /> */}
								</span>
							)}
							{isLoading && (
								<span className="indicator-progress" style={{ display: 'block' }}>
									Please wait...
									{/* <FormattedMessage id="AUTH.LOGIN.LOADING" /> */}
									<span className="spinner-border spinner-border-sm align-middle ms-2" />
								</span>
							)}
						</button>
					</div>

					{/* <div className="text-gray-500 text-center fw-semibold fs-6">
						Not a Member yet?
						<Link to="/auth/registration" className="link-primary">
							Sign up
						</Link>
					</div> */}
				</form>
			</div>
		</div>
	);
};

export default Login;
