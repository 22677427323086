import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import Details from '../details/Details';
import { paymentRuleSelectOptions } from '../settingsComponents/settingsOptions';
import NoShowFee from './NoShowFee';

const PaymentsFormModal = ({
	register,
	control,
	reset,
	watch,
	paymentData,
	setPaymentData,
	handleCancelButton,
	isEditMode,
	setValue,
	createNewRule,
}) => {
	return (
		<>
			<Details register={register} reset={reset} control={control} watch={watch} paymentData={paymentData} isEditMode={isEditMode} />

			<NoShowFee
				register={register}
				control={control}
				watch={watch}
				paymentData={paymentData}
				setPaymentData={setPaymentData}
				isEditMode={isEditMode}
				setValue={setValue}
			/>

			<div className="d-flex justify-content-end">
				<button type="submit" className="btn btn-sm btn-light-primary btn-color-primary mt-2 d-flex mx-5" onClick={createNewRule}>
					<FormattedMessage id="GENERAL.ADD" />
				</button>

				<button
					type="button"
					onClick={handleCancelButton}
					className="btn btn-sm btn-light-danger btn-color-danger d-flex align-items-center mt-2"
				>
					<FormattedMessage id="GENERAL.CANCEL" />
				</button>
			</div>
		</>
	);
};

export default PaymentsFormModal;
