import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { DAYS } from '../../utils/options';
import { bookingOptions, dayOptions, timeOptions } from './openingHoursOptions';

const LocationDayHoursDropdowns = (props) => {
	const { register, day, control, setDay } = props;

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row pb-5">
				<Controller
					name={`opening_hours[${day}].day`}
					control={control}
					render={({ field: { value } }) => (
						<div className="col-12">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.DAY" />
								<sup>*</sup>
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								{...register(`opening_hours[${day}].day`)}
							>
								{dayOptions.map((dayOption) => (
									<option key={`day-${dayOption.value}`} value={dayOption.label} onClick={() => setDay(dayOption.value)}>
										{dayOption.label}
									</option>
								))}
							</select>
						</div>
					)}
				/>
			</div>
			<div className="row pb-5">
				<Controller
					name={`opening_hours[${day}].start_time`}
					control={control}
					render={({ field: { value, name, ref } }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.FROM" />
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								{...register(`opening_hours[${day}].start_time`)}
							>
								{timeOptions.map((time) => (
									<option key={`time-start-${time.value}`} value={time.label}>
										{time.label}
									</option>
								))}
							</select>
						</div>
					)}
				/>

				<Controller
					name={`opening_hours[${day}].end_time`}
					control={control}
					render={({ field: { value, name, ref } }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.TO" />
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								{...register(`opening_hours[${day}].end_time`)}
							>
								{timeOptions.map((time) => (
									<option key={`time-end-${time.value}`} value={time.label}>
										{time.label}
									</option>
								))}
							</select>
						</div>
					)}
				/>
			</div>
			<div className="row">
				<div className="col-12">
					<p className="fw-bold fs-6 mb-0 pb-3">
						<FormattedMessage id="LOCATION.OPENING_HOURS.LAST_BOOKABLE" />
					</p>
					<p className="mb-0 secondary-text fs-7">
						<FormattedMessage id="LOCATION.OPENING_HOURS.INTERVAL_DURATION_TXT" />
					</p>
				</div>
			</div>
		</>
	);
};

export default LocationDayHoursDropdowns;
