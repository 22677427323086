import clsx from 'clsx';
import React from 'react';

import { toAbsoluteUrl } from '../../_metronic/helpers';
import { BUSINESS_LANGUAGES } from '../../common/constants';
import { isEmpty } from '../../helpers/utils';

interface LanguageSelectorProps {
	language: string;
	onLanguageChange: (language: string, type?: string | undefined) => void;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ language = 'en', onLanguageChange }) => {
	return (
		<div className="row">
			<div className="col-12">
				{BUSINESS_LANGUAGES && !isEmpty(BUSINESS_LANGUAGES)
					? BUSINESS_LANGUAGES.map((bl: { code: string; flag: string; name: string | undefined; abbr: string }) => (
							<span
								key={bl.code}
								id={bl.code}
								className={clsx('flag-image-container p-3', {
									active: language === bl.code,
								})}
								onClick={() => onLanguageChange(bl.code)}
							>
								<img className="flag-image rounded-circle" src={toAbsoluteUrl(bl.flag)} alt={bl.name} />
								<span className="fs-4">{bl.abbr}</span>
							</span>
					  ))
					: null}
			</div>
		</div>
	);
};

export default LanguageSelector;
