import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDebounce } from '../../../_metronic/helpers';
import useTableWithItems from '../../../components/table/useTableWithItems';
import { useSearchContactsQuery } from '../../../reducers/contactApiSlice';
import AddGuests from '../reservationPages/AddGuests';
import GuestsTable from '../reservationPages/GuestsTable';
import { phoneNumberOptions } from '../reservationPages/ResOptions';

const ReservationGuests = ({ control, register, watch, errors, setValue }) => {
	// Watchers
	const people = watch('people');
	const phone = watch('host.phone');
	const hostId = watch('host._id');
	const tabolUserId = watch('host.tabol_user_id');
	const guestUsers = watch('guest_users') ?? [];

	const intl = useIntl();

	const isEmpty = (data) => {
		return typeof data === 'object'
			? Object.keys(data).length === 0 || Object.values(data).some((element) => element === undefined)
			: Array.isArray(data) && data.length === 0 && typeof data !== 'undefined';
	};

	const formatGuestList = (list, type) => {
		if (list && list.length > 0) {
			return list.map((guest) => {
				const phone = type === 'contact' ? guest.phone.replace(/\D/g, '') : guest.phoneNumber.replace(/\D/g, '');
				const prefix = phone.slice(0, 2);
				const phoneNumber = phone.slice(-10);
				return {
					email: guest.email ?? '',
					firstname: type === 'contact' ? guest.fname : guest.firstname ?? '',
					lastname: type === 'contact' ? guest.lname : guest.lastname ?? '',
					id: guest._id ?? '',
					phoneNumber: `+${prefix}${phoneNumber}`,
					type: guest._id ? 'contact' : 'tabol_user',
				};
			});
		}
		return [];
	};

	// State
	const [editingGuest, setEditingGuest] = useState(null);
	const [searchTerm, setSearch] = useState('');
	const [openDropdown, setOpenDropdown] = useState('');
	const [guestList, setGuestList] = useState(!isEmpty(guestUsers) ? formatGuestList(guestUsers, 'contact') : [] ?? []);
	const [availableHours, setAvailableHours] = useState([]);
	const dropdownValue = openDropdown;
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	// Queries
	const { data: availableContacts = [], isSuccess: isContactSuccess } = useSearchContactsQuery({ query: { search: debouncedSearchTerm } });

	useEffect(() => {
		if (availableHours.length > 0) {
			if (phone) {
				const countryCode = phone.slice(0, 3);
				setValue('country_code', countryCode);
			}

			if (hostId) {
				setValue('host.type', 'contact');
				setValue('host._id', hostId);
			} else {
				setValue('host.type', 'tabol_user');
				setValue('host._id', tabolUserId);
			}
		}
	}, [availableHours]);

	const handleContactClick = (contact) => {
		setValue('host.fname', contact.fname);
		setValue('host.lname', contact.lname);
		setValue('host.email', contact.email);
		const prefix = contact.phone.slice(0, 3);
		const phone = contact.phone.slice(-10);
		setValue('selectedPhoneNumberOption', prefix);
		setValue('host.phone', phone);
		setValue('host.type', 'contact');
		setValue('host._id', contact.id);
		setSearch('');
	};

	const handleUserClick = (user) => {
		setValue('host.fname', user.fname);
		setValue('host.lname', user.lname);
		setValue('host.email', user.email);
		const prefix = user.mobile.slice(0, 3);
		const phone = user.mobile.slice(-10);
		setValue('selectedPhoneNumberOption', prefix);
		setValue('host.phone', phone);
		setValue('host.type', 'tabol_user');
		setValue('host._id', user.id);
		setSearch('');
	};

	const handleEditGuest = (id) => {
		const guestToEdit = guestList.find((guest) => guest.id === id);
		setEditingGuest(guestToEdit);
	};

	const handleDeleteGuest = (id) => {
		setGuestList((prevGuestList) => {
			return prevGuestList.filter((guest) => guest.id !== id);
		});
	};

	const handleAddGuest = (guest, isUpdating) => {
		if (isUpdating) {
			const list = () => guestList.map((g) => (g._id === guest.id ? guest : g));
			setGuestList(list());
			setValue('guest_new_contact', list());
			if (guestUsers && guestUsers.length > 0) {
				setValue(`guest_users[${guestUsers.findIndex((g) => g.id === guest.id)}]`, guest);
			}
		} else {
			const list = () => [...guestList, guest];
			setGuestList(list());
			setValue('guest_new_contact', guestList);
			if (guest.type === 'new_contact') {
				setValue(
					'guest_new_contact',
					list().filter((item) => item.type === 'new_contact')
				);
			}
		}
	};

	const handleOpenDropdownChange = (value) => {
		setOpenDropdown(value);
	};

	let content = '';
	const tableValues = useMemo(() => {
		return {
			title: '',
			items: guestList,
			totalRows: guestList ? guestList.length : 0,
			content: <GuestsTable onEditGuest={handleEditGuest} onDeleteGuest={handleDeleteGuest} />,
		};
	}, [guestList]);

	const { table } = useTableWithItems(tableValues);
	if (Object.keys(guestList).length > 0) {
		content = table;
	}

	return (
		<>
			<div className="row pt-5">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="pb-5 mb-0 fs-2 fw-bold">
						<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.GUEST_DETAILS" />
					</div>
				</div>
			</div>
			<div className="row pb-4">
				<div className="col-12">
					<div className="input-group guest-search">
						<input
							type="search"
							className="form-control p-4"
							data-role="tagsinput"
							placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SEARCH_GUEST' })}
							value={searchTerm}
							onChange={(e) => setSearch(e.target.value)}
						/>
					</div>
					{isContactSuccess && (
						<div className="row mt-4">
							{availableContacts.contacts.length > 0 && (
								<div className="list-group">
									<h3>
										<FormattedMessage id="RESERVATION.ADD_GUEST.CONTACTS" />
									</h3>
									{availableContacts.contacts.map((contact) => (
										<div
											key={contact.id}
											className="list-group-item list-group-item-action"
											onClick={() => handleContactClick(contact)}>
											{contact.name} - {contact.phone}
										</div>
									))}
								</div>
							)}
							{availableContacts.users.length > 0 ? (
								<div className="list-group">
									<div className="row">
										<div className="col-12">
											<div className="separator my-5" />
										</div>
									</div>
									<h3>
										<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.USERS" />
									</h3>
									{availableContacts.users.map((user) => (
										<div key={user.id} className="list-group-item list-group-item-action" onClick={() => handleUserClick(user)}>
											{user.fname} {user.lname} - {user.mobile}
										</div>
									))}
								</div>
							) : null}
						</div>
					)}
				</div>
			</div>

			<div className="p-3 generic-border">
				<div className="row">
					<div className="col-12 col-sm-6">
						<div className="input-group">
							<label htmlFor="guest-fname" className="pb-3 mb-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATION.ADD_GUEST.FIRSTNAME" />
								<sup>*</sup>
							</label>
							<input
								type="text"
								id="guest-fname"
								name="guest-fname"
								aria-labelledby="guest-fname"
								className="form-control fs-6 p-4"
								placeholder={intl.formatMessage({ id: 'PLACEHOLDER.FIRST_NAME' })}
								data-np-autofill-type="firstname"
								{...register('host.fname')}
							/>
							{errors?.host?.fname && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.host.fname.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-12 col-sm-6">
						<div className="input-group">
							<label htmlFor="guest-lname" className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATION.ADD_GUEST.LASTNAME" />
								<sup>*</sup>
							</label>
							<input
								type="text"
								id="guest-lname"
								name="guest-lname"
								aria-labelledby="guest-lname"
								className="form-control fs-6 p-4"
								placeholder={intl.formatMessage({ id: 'PLACEHOLDER.LAST_NAME' })}
								data-np-autofill-type="lastname"
								{...register('host.lname')}
							/>
							{errors?.host?.lname && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.host.lname.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="row pt-5">
				<div className="col-12">
					<div className="row">
						<div className="col-12 col-sm-6">
							<div className="input-group">
								<label htmlFor="guest-email" className="pb-3 mb-0 fs-7 fw-bold">
									<FormattedMessage id="RESERVATION.ADD_GUEST.EMAIL" />
								</label>
								<input
									type="email"
									className="form-control p-4"
									id="guest-email"
									name="guest-email"
									aria-describedby="emailHelp"
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.EMAIL' })}
									{...register('host.email')}
								/>
								{errors?.host?.email && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.host.email.message}</span>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="col-12 col-sm-6">
							<div className="row">
								<div className="col-12">
									<div className="pb-1 pt-5 pt-sm-0 fs-7 fw-bold">
										<FormattedMessage id="RESERVATION.ADD_GUEST.PHONENUMBER" />
									</div>
								</div>
							</div>

							<div className="row">
								<Controller
									name="country_code"
									control={control}
									defaultValue={phoneNumberOptions[0].label}
									render={({ field }) => (
										<div className="col-12 col-sm-4 pt-4">
											<select
												className="form-select form-select-solid text-capitalize"
												aria-label="Select example"
												{...register('country_code')}
												placeholder={'Please country code'}>
												{phoneNumberOptions &&
													phoneNumberOptions.length > 0 &&
													phoneNumberOptions.map((code, index) => (
														<option value={code?.value} key={index}>
															{code?.label}
														</option>
													))}
											</select>
										</div>
									)}
								/>
								<div className="col-8">
									<div className="input-group pt-4">
										<input
											type="phone"
											className="form-control p-4"
											id="guest-phone"
											aria-describedby="phoneHelp"
											placeholder={intl.formatMessage({ id: 'PLACEHOLDER.PHONE_NUMBER' })}
											{...register('host.phone')}
										/>
										{errors?.host?.phone && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{errors.host.phone.message}</span>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row pt-5">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="fs-2 fw-bold mb-0">
						<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.OTHER_GUESTS" />
					</div>
				</div>
			</div>

			{content}
			<AddGuests
				onAddGuest={handleAddGuest}
				people={people - 1}
				guestList={guestList}
				openDropdownValue={dropdownValue}
				onOpenDropdownChange={handleOpenDropdownChange}
				editingGuest={editingGuest}
				setEditingGuest={setEditingGuest}
				setValue={setValue}
				setCountryCode={setValue}
			/>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
		</>
	);
};

export default ReservationGuests;
