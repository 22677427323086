export enum DateFormat {
	SHORT_DATE, // 15 Jul 2023
	SHORT_DATETIME, // 15 Jul 2023 12:00
	DAY_MONTH_YEAR, // 20/07/2023
	DAY_MONTH_YEAR_HYPHEN, // 2023-07-20
	DAY_MONTH_YEAR_TIME, // 20/07/2023 12:00
	DAY_MONTH_YEAR_TIME_HYPHEN, // 2024-09-30 18:00,
	DAY_MONTH_IN_FULL_YEAR_TIME, // 10 November 2024 18:00,
	TIME,
}

export const formatDate = (dateString: string, format: DateFormat): string => {
	const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const date = new Date(dateString);
	const day = String(date.getUTCDate()).padStart(2, '0');
	const month = String(date.getUTCMonth() + 1).padStart(2, '0');
	const year = date.getUTCFullYear();
	const hours = String(date.getUTCHours()).padStart(2, '0');
	const minutes = String(date.getUTCMinutes()).padStart(2, '0');
	const locale = sessionStorage.getItem('language') === 'en' ? 'en-GB' : sessionStorage.getItem('language') ?? 'en-GB';

	switch (format) {
		case DateFormat.SHORT_DATE:
			return `${day} ${shortMonthNames[month]} ${year}`;
		case DateFormat.SHORT_DATETIME:
			return `${day} ${shortMonthNames[month]} ${year} ${hours}:${minutes}`;
		case DateFormat.DAY_MONTH_YEAR:
			return `${day}/${month}/${year}`;
		case DateFormat.DAY_MONTH_YEAR_HYPHEN:
			return `${year}-${month}-${date.getDate().toString().padStart(2, '0')}`;
		case DateFormat.DAY_MONTH_YEAR_TIME:
			return `${day}/${month}/${year} ${hours}:${minutes}`;
		case DateFormat.DAY_MONTH_YEAR_TIME_HYPHEN:
			return `${year}-${month}-${date.getDate().toString().padStart(2, '0')} ${hours}:${minutes}`;
		case DateFormat.DAY_MONTH_IN_FULL_YEAR_TIME:
			return `${new Intl.DateTimeFormat(locale, {
				// 10 November 2024
				day: 'numeric',
				month: 'long',
				year: 'numeric',
			}).format(date)}`;
		case DateFormat.TIME:
			return `${hours}:${minutes}`;

		default:
			throw new Error('Unsupported date format');
	}
};
