import { FormattedMessage } from 'react-intl';

import FilterBody from './FilterBody';
import FilterHeader from './FilterHeader';

const Filters = ({ filters, onApply, onReset }) => {
	return (
		<>
			<FilterHeader>
				<div className="row">
					<div className="col-12">
						<div className="fw-bold fs-6 py-5">
							<FormattedMessage id="FILTER.OPTIONS" />
						</div>
					</div>
				</div>
			</FilterHeader>
			<div className="separator" />
			<FilterBody filters={filters} onApply={onApply} onReset={onReset} />
		</>
	);
};

export default Filters;
