export const ruleTypeOptions = [{ value: 'per_booking', label: 'Per Booking' }];

export const chargeTypeOptions = [{ value: 'pre_payment', label: 'Pre Payment' }];

export const minGuestsOptions = [
	{ value: '1', label: 'Always (1)' },
	...Array.from({ length: 49 }, (_, i) => ({
		value: `${i + 2}`,
		label: `${i + 2}`,
	})),
];
