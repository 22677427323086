import apiSlice from 'src/app/services/apiSlice';
import prepareURL from 'src/common/prepareURL';

const basePath = '/account';

const settingsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAccount: builder.query({
			query: (args) => prepareURL(basePath, args),
			providesTags: (result, error) => {
				if (!error && result?.account) {
					return [{ type: 'Account', id: 'PARTIAL-LIST' }];
				}
				return [];
			},
		}),
		updateAccount: builder.mutation({
			query: (args) => ({
				url: prepareURL(basePath, args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					return [{ type: 'Account', id: 'PARTIAL-LIST' }];
				}
			},
		}),
		changeUsername: builder.mutation({
			query: (args) => ({
				url: prepareURL(basePath + '/username', args),
				method: 'PUT',
				body: args.body
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					return [{ type: 'Account', id: 'PARTIAL-LIST' }];
				}
			},
		}),
		changePassword: builder.mutation({
			query: (args) => ({
				url: prepareURL(basePath + '/password', args),
				method: 'PUT',
				body: args.body
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					return [{ type: 'Account', id: 'PARTIAL-LIST' }];
				}
			},
		}),
		deactivateAccount: builder.mutation({
			query: (args) => ({
				url: prepareURL(basePath, args),
				method: 'DELETE'
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					return [{ type: 'Account', id: 'PARTIAL-LIST' }];
				}
			},
		})
	}),
});

const { useGetAccountQuery, useUpdateAccountMutation, useChangeUsernameMutation, useDeactivateAccountMutation, useChangePasswordMutation } = settingsApiSlice;

export { useChangePasswordMutation, useChangeUsernameMutation, useDeactivateAccountMutation, useGetAccountQuery, useUpdateAccountMutation };

