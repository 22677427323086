/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { DevTool } from '@hookform/devtools';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BUSINESS_LANGUAGES } from 'src/common/constants';
import useModal from 'src/components/modal/useModal';

import DurationTimeSetting from './details/DurationTimeSettings';
import OnlineBookingsSettings from './settingsComponents/OnlineBookingsSettings';
import PeopleSettings from './settingsComponents/PeopleSettings';
import RestFieldsSettings from './settingsComponents/RestFieldsSettings';

const initialValues = {
	maxNotice: '',
	vat: '',
};

const TabSettings = ({ register, control, watch }) => {
	const { ref } = useModal();
	const [openDropdown, setOpenDropdown] = useState('');

	const toggleDropdown = (name) => {
		setOpenDropdown(openDropdown === name ? '' : name);
	};

	const managingTables = useRef();
	const acceptingOnline = useRef();
	const maxPeopleBooking = useRef();
	const minPeopleBooking = useRef();
	const timeInterval = useRef();
	const defaultInterval = useRef();
	const minTime = useRef();
	const maxTime = useRef();
	const maxSimilarBooking = useRef();
	const paymentRule1 = useRef();
	const paymentRule2 = useRef();

	const dropdownRefs = [
		managingTables,
		acceptingOnline,
		maxPeopleBooking,
		minPeopleBooking,
		timeInterval,
		defaultInterval,
		minTime,
		maxTime,
		maxSimilarBooking,
		paymentRule1,
		paymentRule2,
	];

	useEffect(() => {
		const handleClick = (ev) => {
			const isClickInsideDropdown = dropdownRefs.some((menuRef) => menuRef.current && menuRef.current.contains(ev.target));
			const isClickInsideAnOpenDropdown = openDropdown !== '';

			if (!isClickInsideDropdown && isClickInsideAnOpenDropdown) {
				setOpenDropdown('');
			}
		};

		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [dropdownRefs, openDropdown]);

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1" ref={ref}>
				<OnlineBookingsSettings
					register={register}
					openDropdown={openDropdown}
					setOpenDropdown={setOpenDropdown}
					control={control}
					managingTables={managingTables}
					acceptingOnline={acceptingOnline}
					toggleDropdown={toggleDropdown}
				/>

				<PeopleSettings control={control} register={register} watch={watch} />

				<DurationTimeSetting
					register={register}
					watch={watch}
					openDropdown={openDropdown}
					setOpenDropdown={setOpenDropdown}
					control={control}
					toggleDropdown={toggleDropdown}
					timeInterval={timeInterval}
					defaultInterval={defaultInterval}
					minTime={minTime}
					maxTime={maxTime}
				/>

				<RestFieldsSettings
					register={register}
					watch={watch}
					openDropdown={openDropdown}
					setOpenDropdown={setOpenDropdown}
					control={control}
					toggleDropdown={toggleDropdown}
					maxSimilarBooking={maxSimilarBooking}
					paymentRule1={paymentRule1}
					paymentRule2={paymentRule2}
				/>
			</form>
		</div>
	);
};

export default TabSettings;
