import { createSlice } from '@reduxjs/toolkit';

const initialModalState = {
	active: [],
};

const modalSlice = createSlice({
	name: 'modals',
	initialState: initialModalState,
	reducers: {
		setShowModal: (state, action) => {
			const { type, show = true, payload } = action.payload;

			if (show) {
				state.active.push({ type, show, payload });
				return;
			}

			const filteredModals = state.active.filter((modal) => modal.type !== type);
			state.active = filteredModals;
		},
	},
});

const selectModal = (state, modalType) => {
	const selectedModal = state.modals.active.find((modal) => modal.type === modalType);

	const modal = {
		show: selectedModal ? selectedModal.show : false,
		payload: selectedModal ? selectedModal?.payload : null,
	};
	return modal;
};
const areModalsActive = (state) => state.modals.active.length > 0;

const { setShowModal } = modalSlice.actions;

export { areModalsActive, selectModal, setShowModal };

export default modalSlice.reducer;
