import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { defaultDurationOptions, maxTimeInAdvanceOptions, minTimeInAdvanceOptions, timeIntervalOptions } from '../settingsComponents/settingsOptions';

const DurationTimeSetting = (props) => {
	const { register, watch, control } = props;
	const defaultDuration = watch('online_booking_settings.default_duration_mins') ?? 120;
	const maxAdvanceTime = watch('online_booking_settings.max_advance_time_days') ?? 180;
	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="LOCATION.SETTINGS.DURATION_TIME" />
					</div>
				</div>
			</div>
			<div className="row pb-5">
				<Controller
					name="online_booking_settings.time_interval_mins"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.TIME_INTERVAL" />
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								{...register('online_booking_settings.time_interval_mins')}>
								{timeIntervalOptions.map((intervalOption, index) => (
									<option value={intervalOption.value} key={index}>
										{intervalOption.label}
									</option>
								))}
							</select>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.TIME_INTERVAL_TXT" />
							</p>
						</div>
					)}
				/>
				<Controller
					name="online_booking_settings.default_duration_mins"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.DEFAULT_DURATION" />
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								defaultValue={defaultDuration}
								{...register('online_booking_settings.default_duration_mins')}>
								{defaultDurationOptions.map((durationOption, index) => (
									<option value={durationOption.value} key={index}>
										{durationOption.label}
									</option>
								))}
							</select>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.DEFAULT_DURATION_TXT" />
							</p>
						</div>
					)}
				/>
			</div>
			<div className="row">
				<Controller
					name="online_booking_settings.min_advance_time_mins"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.MIN_TIME_ADVANCE" />
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								{...register('online_booking_settings.min_advance_time_mins')}>
								{minTimeInAdvanceOptions.map((minTimeOption, index) => (
									<option value={minTimeOption.value} key={index}>
										{minTimeOption.label}
									</option>
								))}
							</select>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.MIN_TIME_ADVANCE_TXT" />
							</p>
						</div>
					)}
				/>
				<Controller
					name="online_booking_settings.max_advance_time_days"
					control={control}
					defaultValue={maxAdvanceTime}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.MAX_TIME_ADVANCE" />
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Select example"
								{...register('online_booking_settings.max_advance_time_days')}>
								{maxTimeInAdvanceOptions.map((maxTimeOption, index) => (
									<option value={maxTimeOption.value} key={index}>
										{maxTimeOption.label}
									</option>
								))}
							</select>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.MAX_TIME_ADVANCE_TXT" />
							</p>
						</div>
					)}
				/>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
		</>
	);
};

export default DurationTimeSetting;
