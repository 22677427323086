import { useTranslationsLanguagesQuery } from '../reducers/translationLanguagesApiSlice';

const DEFAULT_PAGE: number = 1;
const DEFAULT_SIZE: number = 10;
const API_DEFAULT_SIZE: number = 10;

const SOCIAL: string[] = ['Email', 'Phone', 'Web', 'Facebook', 'YouTube', 'Instagram', 'LinkedIn', 'Tik Tok', 'Tripadvisor', 'Google My Business'];
// const { data: BUSINESS_LANGUAGES } = useTranslationsLanguagesQuery();
let BUSINESS_LANGUAGES: { code: string; name: string; abbr: string; flag: string }[] = [
	{
		code: 'el',
		name: 'Greek',
		abbr: 'GR',
		flag: '/media/flags/greece.svg',
	},
	{
		code: 'en',
		name: 'English(US)',
		abbr: 'EN',
		flag: '/media/flags/united-states.svg',
	},
];
export { API_DEFAULT_SIZE, BUSINESS_LANGUAGES, DEFAULT_PAGE, DEFAULT_SIZE, SOCIAL };
