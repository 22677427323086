import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers';
import { BUSINESS_LANGUAGES, SOCIAL } from '../../common/constants';
import { fillSupportedLangs } from '../../common/misc';
import { CheckBox } from '../../components/input';
import useModal from '../../components/modal/useModal';
import Divider from '../../components/utils/Divider';
import Label from '../../components/utils/Label';
import { devMode } from '../../helpers/utils';
import { useCreateBusinessMutation, useEditBusinessMutation, useGetBusinessQuery } from '../../reducers/business/businessApiSlice';
import { useFilesQuery, useUploadImageMutation } from '../../reducers/fileApiSlice';

const validationSchema = Yup.object().shape({
	title: Yup.array().of(
		Yup.object()
			.shape({
				lng: Yup.string(),
				text: Yup.string(),
			})
			.test('title-text-validation', 'Title should have a minimum 3 characters', (val) => {
				if (val.lng === BUSINESS_LANGUAGES[0].code) {
					return val.text.length >= 3;
				}

				return val.text === '' || val.text.length >= 3;
			})
	),
	logo: Yup.mixed().required('Logo is required'),
	url: Yup.string().url('Invalid URL format'),
	social: Yup.array().of(
		Yup.object().shape({
			type: Yup.string(),
			val: Yup.string().url('Invalid social URL format'),
		})
	),
	vat: Yup.string().when('validEuVat', {
		is: false,
		then: Yup.string().required('VAT is required when EU VAT is empty'),
		otherwise: Yup.string(),
	}),
	validEuVat: Yup.boolean(),
	tncs: Yup.array().of(
		Yup.object()
			.shape({
				lng: Yup.string(),
				text: Yup.string(),
			})
			.test('tncs-text-validation', 'T&Cs must be a maximum of 300 characters', (val) => val.text === '' || val.text.length <= 300)
	),
});

const initialValues = {
	title: fillSupportedLangs({ lng: '', text: '' }),
	logo: null,
	url: '',
	social: [{ type: SOCIAL[0], val: '' }],
	vat: '',
	validEuVat: false,
	tncs: fillSupportedLangs({ lng: '', text: '' }),
};

const BusinessModalBody = () => {
	const { id, editMode, ref, onClose } = useModal();

	const [editLogo, setEditLogo] = useState(false);
	const [titleLang, setTitleLang] = useState(BUSINESS_LANGUAGES[0].code);
	const [tnCsLang, setTncsLang] = useState(BUSINESS_LANGUAGES[0].code);
	const [availableSocials, setAvailableSocials] = useState(SOCIAL.slice(1));

	const [createBusiness] = useCreateBusinessMutation();
	const [editBusiness] = useEditBusinessMutation();

	const intl = useIntl();

	const {
		handleSubmit,
		register,
		getValues,
		setValue,
		setError,
		watch,
		reset,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: initialValues,
		mode: 'onSubmit',
		resolver: yupResolver(validationSchema),
	});

	useImperativeHandle(ref, () => ({ onSubmit: () => handleSubmit(onSubmit)() }));

	const args = { path: { id } };
	const { data: business, isSuccess: isBusinessSuccess } = useGetBusinessQuery(args, { skip: !editMode });
	const { data: businessLogo, isSuccess: isBusinessLogoSuccess } = useFilesQuery(business?.logo, { skip: !editMode || !business?.logo });

	useEffect(() => {
		if (editMode && isBusinessSuccess) {
			const formData = { ...business };

			if (isBusinessLogoSuccess && businessLogo) {
				const { media } = businessLogo;
				formData.logo = {
					0: {
						id: business.logo,
						type: media.type,
						name: media.title,
						urlSm: media.url_sm,
					},
				};
			}

			reset(formData);
		}
	}, [business, businessLogo, editMode, isBusinessLogoSuccess, isBusinessSuccess, reset]);

	useEffect(() => {
		const subscription = watch(({ social }) => {
			const filteredSocials = SOCIAL.filter((option) => social && !social.some((obj) => obj.type === option));
			setAvailableSocials(filteredSocials);
		});

		return () => subscription.unsubscribe();
	}, [watch]);

	const logoName = watch('logo[0].name');

	const { fields: titleFields } = useFieldArray({ name: 'title', control });
	const { fields: tncsFields } = useFieldArray({ name: 'tncs', control });
	const { fields: socialFields, append: appendSocial, remove: removeSocial } = useFieldArray({ name: 'social', control });

	const [uploadFiles] = useUploadImageMutation();
	const handleUploadLogo = async () => {
		const image = getValues('logo[0]');

		const formData = new FormData();
		formData.append('image', image);

		const response = await uploadFiles(formData);
		return response.data;
	};

	const onSubmit = async ({ title, vat, validEuVat, url, social, tncs }) => {
		let image = { id: business?.logo };
		if (!editMode || editLogo) {
			image = await handleUploadLogo();
			if (!image) {
				setError('logo', { type: 'custom', message: 'Error on logo upload' });
				return;
			}
		}

		const inputs = {
			title: title[0].text,
			default_locale: title[0].lng,
			logo: image.id,
			vat,
			invalid_eu_vat: validEuVat,
		};

		if (url) {
			inputs.contact_info = [{ type: 'web', val: url }];
		}

		if (social && social.length > 0) {
			const filteredSocial = social.filter((item) => item.val.trim() !== '');

			if (filteredSocial.length > 0) {
				inputs.contact_info = [...(inputs.contact_info || []), ...filteredSocial];
			}
		}

		title.length > 1 &&
			title.slice(1).forEach(({ lng, text }) => {
				if (text.trim() !== '') {
					inputs.translations = {
						...inputs.translations,
						[lng]: {
							...(inputs.translations?.[lng] || {}),
							title: text,
						},
					};
				}
			});

		if (tncs) {
			if (tncs[0] && tncs[0].text.trim() !== '') {
				inputs.tncs = tncs[0].text;
			}

			tncs.length > 1 &&
				tncs.slice(1).forEach(({ lng, text }) => {
					if (text.trim() !== '') {
						inputs.translations = {
							...inputs.translations,
							[lng]: {
								...(inputs.translations?.[lng] || {}),
								tncs: text,
							},
						};
					}
				});
		}
		editMode ? await editBusiness({ path: { id } }, inputs) : await createBusiness(inputs);
		onClose();
	};

	return (
		<div className="modal-content">
			<form ref={ref}>
				<Label title={intl.formatMessage({ id: 'BUSINESS.MODAL.DETAILS' })} />

				<div className="p-3 generic-border">
					<div className="row">
						<div className="col-12">
							{BUSINESS_LANGUAGES.map((bl) => (
								<span
									key={bl.code}
									id={bl.code}
									className={clsx('flag-image-container p-3', { active: titleLang === bl.code })}
									onClick={(e) => setTitleLang(e.currentTarget.id)}
								>
									<img className="flag-image rounded-circle" src={toAbsoluteUrl(bl.flag)} alt={bl.name} />
									<span className="fs-4">{bl.abbr}</span>
								</span>
							))}
						</div>
					</div>

					<div className="row pt-5">
						<div className="col-12">
							{titleFields.map((field, index) => (
								<div key={field.id} className="input-group">
									{field.lng === titleLang && (
										<>
											<label
												htmlFor={`title[${index}].text`}
												className={clsx('form-label pb-3 mb-0 fs-7 fw-bold', {
													required: titleLang === BUSINESS_LANGUAGES[0].code,
												})}
											>
												<FormattedMessage id="BUSINESS.MODAL.TITLE" />
											</label>
											<input
												type="text"
												id={`title[${index}].text`}
												name={`title[${index}].text`}
												aria-labelledby={`title[${index}].text`}
												{...register(`title[${index}].text`)}
												className={clsx('form-control fs-6 p-4', {
													'is-invalid': errors?.title && errors?.title[index]?.message,
												})}
												placeholder={intl.formatMessage({ id: 'BUSINESS.MODAL.ORGANIZATION_NAME' })}
											/>
											{errors?.title && errors?.title[index].root?.message && (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														<span role="alert">{errors.title[index].root.message}</span>
													</div>
												</div>
											)}
										</>
									)}
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="row pt-5">
					<div className="col-12">
						<label className="required pb-3 mb-0 fs-7 fw-bold" htmlFor="logo">
							<FormattedMessage id="BUSINESS.MODAL.UPLOAD" />
						</label>
						<div className="uploader-container p-4 fs-5 fw-bold">
							<div className="uploader-wrapper">
								<input
									type="file"
									id="logo"
									name="logo"
									aria-labelledby="logo"
									{...register('logo')}
									className="custom-checkbox"
									accept="image/jpg, image/jpeg, image/png"
								/>
								<label htmlFor="logo" className="uploader">
									<KTSVG className="svg-icon-4x svg-icon-primary" path="/media/icons/duotune/files/fil009.svg" />
									<div className="uploader-text">
										<span className="fs-bold">
											<FormattedMessage id="FILES.UPLOAD" />
										</span>
										{logoName ? (
											<div
												className="d-inline-flex align-items-center"
												onClick={() => {
													setValue('logo', null);
													setEditLogo(true);
												}}
											>
												<span className="fs-7 text-primary">{logoName}</span>
												<KTSVG className="svg-icon-2x svg-icon-primary px-2" path="/media/icons/duotune/general/gen040.svg" />
											</div>
										) : (
											<span className="fs-7 text-primary">
												<FormattedMessage id="FILES.DRAG.DROP" />
											</span>
										)}
									</div>
								</label>
							</div>
						</div>

						{errors?.logo && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.logo.message}</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<Divider />

				<Label title={intl.formatMessage({ id: 'BUSINESS.MODAL.SOCIAL' })} />

				<div className="row pb-5">
					<div className="col-12">
						<div className="input-group">
							<label htmlFor="url" className="pb-3 mb-0 fs-7 fw-bold">
								<FormattedMessage id="BUSINESS.MODAL.WEBSITE.URL" />
							</label>
							<input
								type="text"
								id="url"
								name="url"
								aria-labelledby="url"
								{...register('url')}
								className={clsx('form-control fs-6 p-4', { 'is-invalid': errors?.url })}
								placeholder={intl.formatMessage({ id: 'BUSINESS.MODAL.WEBSITE.URL' })}
							/>
							{errors?.url && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.url.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>

				{socialFields.map((field, index) => (
					<div className="row pb-5" key={field.id}>
						<div className="col-12 col-sm-3">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="BUSINESS.MODAL.SOCIAL" />
							</div>
							<Controller
								name={`social[${index}].type`}
								control={control}
								defaultValue={field.type}
								render={({ field }) => (
									<select
										{...field}
										className="form-select select-header p-4 text-capitalize" // .form-select-solid
										aria-label="Select example"
									>
										<option key={field.value} value={field.value}>
											{field.value}
										</option>
										{availableSocials.map((option) => (
											<option key={option} value={option}>
												{option}
											</option>
										))}
									</select>
								)}
							/>
						</div>
						<div className="col-12 col-sm-7">
							<div className="input-group">
								<label htmlFor={`social[${index}].val`} className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
									<FormattedMessage id="BUSINESS.MODAL.URL" />
								</label>
								<input
									type="text"
									name={`social[${index}].val`}
									id={`social[${index}].val`}
									aria-labelledby={`social[${index}].val`}
									{...register(`social[${index}].val`)}
									className={clsx('form-control fs-6 p-4', {
										'is-invalid': errors?.social && errors?.social[index]?.val,
									})}
									placeholder="http://www.example.com"
								/>
								{errors?.social && errors?.social[index]?.val && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.social[index].val.message}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="col-12 col-sm-2 pt-5 pt-sm-0 d-flex align-items-end">
							<button
								type="button"
								onClick={() => removeSocial(index)}
								disabled={socialFields.length < 2}
								className="btn btn-sm btn-light-danger btn-color-danger w-100 d-flex justify-content-center align-items-center"
							>
								<KTSVG className="svg-icon-1" path="/media/icons/duotune/general/gen027.svg" />
								<div className="fs-7">
									<FormattedMessage id="GENERAL.DELETE" />
								</div>
							</button>
						</div>
					</div>
				))}

				{availableSocials.length > 0 && (
					<div className="row">
						<div className="col-12 col-sm-3">
							<button
								type="button"
								onClick={() => appendSocial({ type: availableSocials[0], val: '' })}
								className="generic-button fw-bold fs-6 w-100"
							>
								<FormattedMessage id="BUSINESS.MODAL.ADD.SOCIAL" />
							</button>
						</div>
					</div>
				)}

				<Divider />

				<Label title={intl.formatMessage({ id: 'BUSINESS.MODAL.VAT' })} />

				<div className="row pb-5">
					<div className="col-12">
						<div className="input-group">
							<label htmlFor="business-vat" className="form-label pb-3 mb-0 fs-7 fw-bold required">
								<FormattedMessage id="GENERAL.VAT.NUMBER" />
							</label>
							<input
								type="text"
								className="form-control fs-6 p-4"
								id="business-vat"
								name="business-vat"
								aria-labelledby="business-vat"
								placeholder="14291053"
								{...register('vat')}
							/>
							{errors?.vat && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.vat.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<p className="secondary-text fw-bold mb-0 fs-7">
							<FormattedMessage id="GENEVAL.VAT.NUMBER.MSG" />
							<a href="https://en.wikipedia.org/wiki/VAT_identification_number">
								<FormattedMessage id="GENEVAL.VAT.NUMBER.COUNTRIES.LIST" />
							</a>
						</p>
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<CheckBox
							name="validEuVat"
							title={intl.formatMessage({ id: 'BUSINESS.MODAL.VAT.NOT_VALID' })}
							register={register}
							errors={errors}
						/>
					</div>
				</div>

				<Label title={intl.formatMessage({ id: 'BUSINESS.MODAL.TERMS' })} />

				<div className="p-3 generic-border">
					<div className="row">
						<div className="col-12">
							{BUSINESS_LANGUAGES.map((bl) => (
								<span
									key={bl.code}
									id={bl.code}
									className={clsx('flag-image-container p-3', { active: tnCsLang === bl.code })}
									onClick={(e) => setTncsLang(e.currentTarget.id)}
								>
									<img className="flag-image rounded-circle" src={toAbsoluteUrl(bl.flag)} alt={bl.name} />
									<span className="fs-4">{bl.abbr}</span>
								</span>
							))}
						</div>
					</div>

					<div className="row pt-5">
						<div className="col-12">
							{tncsFields.map((field, index) => (
								<div key={field.id} className="input-group">
									{field.lng === tnCsLang && (
										<>
											<textarea
												id={`tncs[${index}].text`}
												{...register(`tncs[${index}].text`)}
												className={clsx('full-width-textarea fs-6 p-4', {
													'is-invalid': errors?.tncs && errors?.tncs[index]?.message,
												})}
												rows="4"
												cols="50"
												placeholder=""
											/>
											{errors?.tncs && errors?.tncs[index]?.message && (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block defi">
														<span role="alert">{errors.tncs[index].message}</span>
													</div>
												</div>
											)}
										</>
									)}
								</div>
							))}
						</div>
					</div>
				</div>

				{/* <Divider />

				<Label title="Stripe" />

				<div className="row pb-5">
					<div className="col-12">
						<p className="mb-0 dark-text">
							Set up and manage your Stripe account to receive online payments with daily payouts. Having your own Stripe account
							ensures that you pay the lowest transaction fees - giving you full transparency and control of your earnings.
						</p>
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<p className="mb-0 dark-text">
							Your Stripe account setup is almost done, please complete it to start receiving online payments.
						</p>
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12 col-sm-4">
						<button type="button" className="btn btn-primary fw-bold w-100 fs-7 p-4">
							Resume Stripe Setup
						</button>
					</div>
				</div>

				<Label title="Disconnect Stripe Account" />

				<div className="row pb-5">
					<div className="col-12">
						<p className="dark-text mb-0">
							If you connected the wrong Stripe account or need to start settings up Stripe from scratch, you can disconnect the current
							Stripe account. WARNING: Any form of online payments and features relying on online payments, like booking prepayments and
							no-show fees , will stop working until you have fully set up another Stripe account. You will only be able to refund
							payments through the old account using the Stripe Dashboard of the old account.
						</p>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-sm-4">
						<button type="button" className="generic-button text-muted p-4 fw-bold fs-7 w-100">
							Disconnect Stripe Account
						</button>
					</div>
				</div> */}
			</form>

			{devMode && <DevTool control={control} />}
		</div>
	);
};

export default BusinessModalBody;
