import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialModalFormState = {
	active: [],
};

const modalFormSlice = createSlice({
	name: 'modalForms',
	initialState: initialModalFormState,
	reducers: {
		setShowModalForm: (state, action) => {
			const { name, show = true, mode, args = {} } = action.payload;
			if (show) {
				state.active.push({
					name,
					show,
					mode: {
						create: mode === 'create',
						edit: mode === 'edit',
						view: mode === 'view',
					},
					args,
				});
				return;
			}

			state.active = state.active.filter((modal) => modal.name !== name);
		},
	},
});

const selectModalForms = ({ modalForms }) => modalForms.active;

export const selectMemoModalForm = (name) =>
	createSelector([selectModalForms], (active) => {
		const selectedModalForm = active.find((modal) => modal.name === name);
		let index = -1;
		if (selectedModalForm) {
			index = active.findIndex((modal) => modal.name === name);
		}
		return {
			show: selectedModalForm?.show,
			mode: selectedModalForm?.mode || {},
			args: selectedModalForm?.args,
			index,
		};
	});
const areModalFormsActive = (state) => state.modalForms.active.length > 0;
const { setShowModalForm } = modalFormSlice.actions;

export { areModalFormsActive, setShowModalForm };

export default modalFormSlice.reducer;
