import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	service: [],
};

const areasSlice = createSlice({
	name: 'service',
	initialState,
	reducers: {
		setAreasState: (state, action) => {
			if (action.payload) {
				state.service = action.payload ?? {};
			}
		},
	},
});

export const { setAreasState } = areasSlice.actions;
export const selectServiceAreas = (state) => state.service;
export default areasSlice.reducer;
