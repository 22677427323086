import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { KTSVG } from 'src/_metronic/helpers';
import { setShowModal } from 'src/reducers/modalSlice';

import { selectActiveBusiness, selectActiveStore, selectMemoBusinessStores, setActiveStore } from '../../../../reducers/business/businessSlice';

interface Store {
	_id: string;
	title: string;
}

const HeaderStoreMenu = () => {
	const activeStore = useSelector(selectActiveStore);
	const activeBusiness = useSelector(selectActiveBusiness);
	const businessStores = useSelector(selectMemoBusinessStores(activeBusiness?._id));
	const [selectedStore, setSelectedStore] = useState(activeStore);
	const dispatch = useDispatch();

	const handleActiveStoreToggle = (store: Store): void => {
		setSelectedStore(store);
		dispatch(
			setActiveStore({
				_id: selectedStore?._id,
				title: selectedStore?.title,
			})
		);
	};

	return (
		// <div
		// 	className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-150px"
		// 	data-kt-menu="true">
		// 	{businessStores.length > 0 &&
		// 		activeStore &&
		// 		businessStores.map(({ _id, title }: { _id: string; title: string }) => (
		// 			<div key={_id} className="form-check form-switch pb-2">
		// 				<input
		// 					className="form-check-input"
		// 					type="checkbox"
		// 					role="switch"
		// 					id="_id"
		// 					checked={activeStore._id === _id}
		// 					onChange={() => dispatch(setActiveStore({ _id, title }))}
		// 				/>
		// 				<label className="form-check-label" htmlFor="_id">
		// 					{title}
		// 				</label>
		// 			</div>
		// 		))}
		// 	<div className="menu-item px-5">
		// 		<a className="menu-link px-4">
		// 			<i className="fas fa-square-plus fs-2 me-2" />
		// 			Add Store
		// 		</a>
		// 	</div>
		// </div>
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary py-4 fs-6 p-4 w-275px"
			data-kt-menu="true"
		>
			{businessStores.length > 0 &&
				activeStore &&
				businessStores.map((store: Store) => (
					<div key={store?._id} className="d-flex justify-content-start align-items-center pb-3">
						<div className="form-check form-check-custom form-check-solid">
							<input
								className="form-check-input"
								type="radio"
								value=""
								id="online"
								name="online"
								checked={activeStore._id === store?._id}
								onChange={() => handleActiveStoreToggle(store)}
							/>
							<label className="form-check-label me-2" htmlFor="online">
								{store?.title}
							</label>
						</div>
					</div>
				))}
			<div className="menu-item" onClick={() => dispatch(setShowModal({ type: 'location_new' }))}>
				<a className="menu-link justify-content-center">
					{/* <i className="fas fa-square-plus fs-2 me-3" /> */}
					<KTSVG path="/media/icons/duotune/general/gen035.svg" className="svg-icon-primary svg-icon-1 me-3" />
					<FormattedMessage id="HEADER.MENU.ORGANIZATION.ADD" />
				</a>
			</div>
		</div>
	);
};

export { HeaderStoreMenu };
