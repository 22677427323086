import clsx from 'clsx';
import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';

type Props = {
	to: string;
	title: string;
	icon?: string;
	fontIcon?: string;
	image?: string;
	menuTrigger?: 'click' | `{default:'click', lg: 'hover'}`;
	menuPlacement?: 'right-start' | 'bottom-start' | 'left-start';
	hasArrow?: boolean;
	hasBullet?: boolean;
	isMega?: boolean;
	classname: string;
	identifier?: string;
};

const MenuInnerWithSub: FC<Props & WithChildren> = ({
	children,
	to,
	title,
	icon,
	fontIcon,
	image,
	menuTrigger,
	menuPlacement,
	hasArrow = false,
	hasBullet = false,
	isMega = false,
	classname,
	identifier,
}) => {
	const menuItemRef = useRef<HTMLDivElement>(null);
	const { pathname } = useLocation();

	useEffect(() => {
		if (menuItemRef.current && menuTrigger && menuPlacement) {
			menuItemRef.current.setAttribute('data-kt-menu-trigger', menuTrigger);
			menuItemRef.current.setAttribute('data-kt-menu-placement', menuPlacement);
		}
	}, [menuTrigger, menuPlacement]);

	return (
		<div
			ref={menuItemRef}
			className={clsx('menu-item menu-lg-down-accordion overflow-hidden', {
				'mx-3': identifier,
			})}
		>
			<span
				className={clsx(
					'menu-link py-3',
					{
						'menu-here': checkIsActive(pathname, to),
					},
					classname
				)}
			>
				{hasBullet && (
					<span className="menu-bullet">
						<span className="bullet bullet-dot"></span>
					</span>
				)}

				{icon && (
					<span className="menu-icon">
						<KTSVG path={icon} className="svg-icon-2" />
					</span>
				)}

				{fontIcon && (
					<span className="menu-icon">
						<i className={clsx('bi fs-3', fontIcon)}></i>
					</span>
				)}

				{image ? (
					<span className="menu-image">
						<img width="22" height="22" className="object-fit-cover" src={'https://test-api.tabol.io/' + image} alt={title + ' Logo'} />
					</span>
				) : (
					<span className="menu-image">
						<KTSVG path="/media/icons/duotune/ecommerce/ecm004.svg" className="d-block svg-icon-1" />
					</span>
				)}

				<span className="menu-title text-white text-start ps-4">{title}</span>

				{hasArrow && <span className="menu-arrow"></span>}
			</span>
			<div
				className={clsx(
					'menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown',
					isMega ? 'w-100 w-lg-850px p-5 p-lg-5' : 'menu-rounded-0 py-lg-4 sidebar-width'
				)}
				data-kt-menu-dismiss="true"
			>
				{children}
			</div>
		</div>
	);
};

export { MenuInnerWithSub };
