import React from 'react';

import useModal from '../../../components/modal/useModal';
import ReservationDetails from '../list/ReservationDetails';
import ReservationGuests from '../list/ReservationGuests';
import ReservationNotes from '../list/ReservationNotes';

const TabDetails = ({ register, control, watch, reset, trigger, setValue, errors, store, isStoreLoading }) => {
	const { ref } = useModal();

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1" ref={ref}>
				<ReservationDetails
					errors={errors}
					register={register}
					control={control}
					watch={watch}
					trigger={trigger}
					setValue={setValue}
					store={store}
					isStoreLoading={isStoreLoading}
				/>
				<ReservationGuests errors={errors} register={register} control={control} watch={watch} setValue={setValue} reset={reset} />
				<ReservationNotes register={register} watch={watch} />
			</form>
		</div>
	);
};

export default TabDetails;
