import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';

import { FC, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useGetTranslationsQuery } from 'src/app/services/apiSlice';
import { getSessionStorage, setSessionStorage } from 'src/common/storage';

import { WithChildren } from '../helpers';
import { LayoutSplashScreen } from '../layout/core';
import { I18nContext, I18nContextDefaultProps } from './Metronici18n';

export const { REACT_APP_API_X_LOCALE: X_LOCALE } = process.env;

let allMessages = {};

export type Translations = {
	lang: String;
	entries: Object;
};

export type StoredLanguages = {
	lastFetched: number; //milliseconds
	ttl: number;
	translations: Set<Translations>;
};

const getTranslationsFromStorage = (): StoredLanguages | null => {
	let storedLanguages = localStorage.getItem('translations');

	if (storedLanguages) {
		return JSON.parse(storedLanguages);
	}

	return null;
};

const shouldFetchTranslations = (storedLanguages: StoredLanguages | null = null): boolean => {
	let _trans = storedLanguages || getTranslationsFromStorage();
	if (!_trans) {
		return true;
	}
	const ttl = 10000 * 6 * 60;
	const now = Date.now();
	const diff = now - _trans.lastFetched;
	return diff > ttl;
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
	const [locale, setLocale] = useState(getSessionStorage('language') ?? X_LOCALE);

	let translations: StoredLanguages | null;
	translations = getTranslationsFromStorage();
	if (translations) {
		allMessages[locale] = translations.translations;
	}

	const { data, isLoading, refetch } = useGetTranslationsQuery(); //  { skip: !shouldFetchTranslations() }

	if (data && !isLoading) {
		allMessages[locale] = { ...data.translations, lang: locale, lastFetched: new Date().getTime() };
	}

	const messages = allMessages[locale];

	let props = I18nContextDefaultProps;
	props.changeLanguage = (lang) => {
		setSessionStorage('language', lang);
		setLocale(lang);
		refetch();
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<I18nContext.Provider value={I18nContextDefaultProps}>
			<IntlProvider locale={locale} messages={messages}>
				{children}
			</IntlProvider>
		</I18nContext.Provider>
	);
};

export { I18nProvider };
