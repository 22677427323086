import clsx from 'clsx';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { BUSINESS_LANGUAGES } from '../../../../../common/constants';
import LanguageSelector from '../../../../../components/languageSelector/LanguageSelector';
import { bookingPeopleOnlineOptions } from './settingsOptions';

const PeopleSettings = (props) => {
	const { control, register, watch } = props;
	const maxPeople = watch('online_booking_settings.max_people') ?? 15;
	const maxPeopleNotice = watch('online_booking_settings.max_people_notice');
	const [maxNoticeLang, setMaxNoticeLang] = useState(BUSINESS_LANGUAGES[0].code);
	const translations = BUSINESS_LANGUAGES.reduce((acc, lang) => {
		acc[lang?.code] = {
			max_people_notice: watch(`online_booking_settings.translations[${lang?.code}].max_people_notice`),
		};
		return acc;
	}, {});
	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="LOCATION.SETTINGS.PEOPLE" />
					</div>
				</div>
			</div>

			<div className="row pb-5">
				<Controller
					name="online_booking_settings.max_people"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.MAX_PEOPLE_BOOKING" />
							</div>
							<select
								className="form-select form-select-solid"
								aria-label="Select example"
								defaultValue={maxPeople}
								{...register('online_booking_settings.max_people')}>
								{bookingPeopleOnlineOptions.map((maxBooking) => (
									<option key={maxBooking.value} value={maxBooking.value}>
										{maxBooking.label}
									</option>
								))}
							</select>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.MAX_PEOPLE_BOOKING_TXT" />
							</p>
						</div>
					)}
				/>
				<Controller
					name="online_booking_settings.min_people"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.MIN_PEOPLE_BOOKING" />
							</div>
							<select
								className="form-select form-select-solid"
								aria-label="Select example"
								{...register('online_booking_settings.min_people')}>
								{bookingPeopleOnlineOptions.map((minBooking) => (
									<option key={minBooking.value} value={minBooking.value} defaultValue={minBooking.value}>
										{minBooking.label}
									</option>
								))}
							</select>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.MIN_PEOPLE_BOOKING_TXT" />
							</p>
						</div>
					)}
				/>
			</div>

			<div className="p-3 generic-border">
				<LanguageSelector language={maxNoticeLang} onLanguageChange={setMaxNoticeLang} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="max-notice" className="form-label pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="LOCATION.SETTINGS.MAX_NOTICE" />
						</label>
						<textarea
							id="max-notice"
							name="max-notice"
							className="full-width-textarea fs-6 p-4"
							rows="4"
							cols="50"
							{...register(
								`online_booking_settings.translations[${maxNoticeLang}].max_people_notice` ??
									'online_booking_settings.max_people_notice'
							)}
							defaultValue={
								translations[maxNoticeLang]?.max_people_notice ? translations[maxNoticeLang]?.max_people_notice : maxPeopleNotice
							}
							value={translations[maxNoticeLang]?.max_people_notice !== '' ? translations[maxNoticeLang]?.max_people_notice : ''}
						/>
						<p className="mb-0 secondary-text fs-7 pt-2">
							<FormattedMessage id="LOCATION.SETTINGS.MAX_NOTICE_TXT" />
						</p>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
		</>
	);
};

export default PeopleSettings;
