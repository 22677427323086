// Range Slider
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap_white.css';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';
import useModal from 'src/components/modal/useModal';

import { customStyles } from '../../../../common/inputStyles';
import { selectActiveBusiness } from '../../../../reducers/business/businessSlice';
import { useGetStoreDetailsQuery } from '../../../../reducers/stores/storeApiSlice';
import LocationSelectFields from './details/LocationSelectFields';
import LocationSocialTextFields from './details/LocationSocialTextFields';
import StoreDetails from './details/StoreDetails';
import StoreDetailsInformation from './details/StoreDetailsInformation';

const animatedComponents = makeAnimated();

const TabDetails = ({ register, control, watch, reset, trigger, setValue, errors }) => {
	const { ref, id, editMode } = useModal();
	const { _id: business_id } = useSelector(selectActiveBusiness);
	const { data: detailsData } = useGetStoreDetailsQuery({ bid: business_id, path: { id: id } }, { skip: !editMode });
	const [openDropdown, setOpenDropdown] = useState('');

	const [formValues, setFormValues] = useState(null);
	const handleVatChange = (isVatDisabled) => {
		setFormValues((prevValues) => ({
			...prevValues,
			vatNumberExists: isVatDisabled,
		}));
	};

	const businessRef = useRef();
	const phoneNumberRef = useRef();
	const currencyRef = useRef();
	const timezoneRef = useRef();

	const dropdownRefs = [businessRef, phoneNumberRef, currencyRef, timezoneRef];

	useEffect(() => {
		const handleClick = (ev) => {
			const isCliCkInsideDropdown = dropdownRefs.some((menuRef) => menuRef.current && menuRef.current.contains(ev.target));
			const isClickInsideAnOpenDropdown = openDropdown !== '';

			if (!isCliCkInsideDropdown && isClickInsideAnOpenDropdown) {
				setOpenDropdown('');
			}
		};

		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [dropdownRefs, openDropdown]);

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1" ref={ref}>
				<StoreDetails errors={errors} register={register} control={control} watch={watch} setValue={setValue} reset={reset} />

				<StoreDetailsInformation
					errors={errors}
					register={register}
					reset={reset}
					control={control}
					watch={watch}
					animatedComponents={animatedComponents}
					customStyles={customStyles}
				/>

				<LocationSelectFields
					register={register}
					reset={reset}
					control={control}
					watch={watch}
					customStyles={customStyles}
					animatedComponents={animatedComponents}
					setValue={setValue}
				/>

				<LocationSocialTextFields
					reset={reset}
					trigger={trigger}
					details={detailsData}
					control={control}
					register={register}
					errors={errors}
					watch={watch}
					setValue={setValue}
					handleVatCheckboxChange={handleVatChange}
				/>
			</form>
		</div>
	);
};

export default TabDetails;
