import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { removeLocalStorage, setLocalStorage } from 'src/common/storage';

const initialState = {
	user: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logOut: (state) => {
			//This is bad
			removeLocalStorage('refreshToken', '');
			removeLocalStorage('accessToken', '');
			removeLocalStorage('user', '');
			state.user = null;
		},
	},
});

const { logOut } = authSlice.actions;

export { logOut };

export default authSlice.reducer;
