import apiSlice from '../../app/services/apiSlice';
import prepareURL from '../../common/prepareURL';
import { getSessionStorage } from '../../common/storage';
import { capitalizeFirstLetter } from '../../components/utils/Capitilizer';
import { Store, StoreParams, StoresApiResponse } from '../../types/stores/StoresApiResponse';

const storesBasePath = 'business/stores';
const filePath = '/../files/';

const storeApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		stores: builder.query<StoresApiResponse, StoreParams>({
			query: (args) => prepareURL(`business/${args?.query?.business_id}/store`, args),
			transformResponse: (response: any) => {
				return response?.result.map((store: Store) => ({
					_id: store['_id'],
					title: store?.title,
					location: `${store?.location['street_address']}, ${store?.location['city']}, ${store?.location['post_code']}, ${store?.location['country']}`,
					description: store?.description,
					number: store?.phone?.val,
					published: store?.published,
					phone: store?.phone,
				}));
			},
			providesTags: ['Stores'],
		}),
		getStoreDetails: builder.query({
			query: (args) => {
				const bid = JSON.parse(getSessionStorage('act_bsn'))?._id;
				return {
					method: 'GET',
					url: prepareURL(`business/${bid ?? args?.bid}/store/${args?.path?.id}`, args),
				};
			},
			providesTags: ['StoreDetails'],
		}),
		getStoreTypes: builder.query({
			query: (args) => ({
				method: 'GET',
				url: prepareURL(`${storesBasePath}/types`, args),
			}),
			transformResponse: (response: any) => {
				return response['types'].map((category: { _id: string; title: string }) => ({
					value: category._id,
					label: capitalizeFirstLetter(category.title),
				}));
			},
		}),
		getStoreTraits: builder.query({
			query: (args) => ({
				method: 'GET',
				url: prepareURL(`${storesBasePath}/traits`, args),
			}),
			transformResponse: (response: any) => {
				const types = ['tags', 'payment_methods', 'suggested_for', 'environment', 'misc', 'services', 'info'];
				return response?.traits
					.filter((item: { type: string }) => types.includes(item.type))
					.map((trait: { _id: string; title: string; type: string }) => ({
						value: trait?._id,
						label: capitalizeFirstLetter(trait?.title),
						type: trait?.type,
					}));
			},
		}),
		getCuisine: builder.query({
			query: (args) => ({
				method: 'GET',
				url: prepareURL(`${storesBasePath}/cuisines`, args),
			}),
			transformResponse: (response: any) => {
				return response['cuisines'].map((cuisine: { _id: string; title: string }) => ({
					value: cuisine._id,
					label: capitalizeFirstLetter(cuisine.title),
				}));
			},
		}),
		getReservationPreferences: builder.query({
			query: (args) => ({
				method: 'GET',
				url: prepareURL(`${storesBasePath}/reservation-prefs`, args),
			}),
			transformResponse: (response: any) => {
				return response['rsv_prefs'].map((preference: { _id: string; title: string }) => ({
					value: preference._id,
					label: capitalizeFirstLetter(preference.title),
				}));
			},
			providesTags: ['ReservationPreferences'],
		}),
		createStore: builder.mutation({
			query: (args) => {
				const bid = JSON.parse(getSessionStorage('act_bsn'))?._id;
				return {
					method: 'POST',
					url: prepareURL(`business/${bid ?? args?.bid}/store`, args),
					body: args.body,
				};
			},
			invalidatesTags: ['Stores'],
		}),
		editStore: builder.mutation({
			query: (args) => {
				const bid = JSON.parse(getSessionStorage('act_bsn'))?._id;
				return {
					method: 'PUT',
					url: prepareURL(`business/${bid ?? args?.bid}/store/${args?.path.id}`, args),
					body: args.body,
				};
			},
			invalidatesTags: ['Stores', 'StoreDetails'],
		}),
		deleteStore: builder.mutation({
			query: (args) => {
				const bid = JSON.parse(getSessionStorage('act_bsn'))?._id;
				return {
					method: 'DELETE',
					url: prepareURL(`business/${bid ?? args?.bid}/store/${args?.path.id}`, args),
				};
			},
			invalidatesTags: ['Stores'],
		}),
		uploadStoreFiles: builder.mutation({
			query: (args) => ({
				url: prepareURL(filePath + 'upload-file', args),
				method: 'POST',
				body: args,
			}),
		}),
		uploadStoreImage: builder.mutation({
			query: (args) => ({
				url: prepareURL(filePath + 'upload-image', args),
				method: 'POST',
				body: args,
			}),
		}),
	}),
});

export const {
	useStoresQuery,
	useGetStoreDetailsQuery,
	useGetStoreTypesQuery,
	useGetStoreTraitsQuery,
	useGetCuisineQuery,
	useGetReservationPreferencesQuery,
	useCreateStoreMutation,
	useEditStoreMutation,
	useDeleteStoreMutation,
	useUploadStoreImageMutation,
	useUploadStoreFilesMutation,
} = storeApiSlice;
