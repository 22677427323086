import { getCountOptions, getTimeIntervalOptions } from '../../utils/options';

export const dayOptions = [
	{ value: 'mon', label: 'Monday' },
	{ value: 'tue', label: 'Tuesday' },
	{ value: 'wed', label: 'Wednesday' },
	{ value: 'thu', label: 'Thursday' },
	{ value: 'fri', label: 'Friday' },
	{ value: 'sat', label: 'Saturday' },
	{ value: 'sun', label: 'Sunday' },
];

export const timeOptions = getTimeIntervalOptions('05:00', '04:45', 15);

export const bookingOptions = getCountOptions(250);
