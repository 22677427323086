import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	availability: [],
};

const availabilitySlice = createSlice({
	name: 'availability',
	initialState,
	reducers: {
		setAvailabilityState: (state, action) => {
			if (action.payload) {
				state.availability = action.payload ?? {};
			}
		},
	},
});

export const { setAvailabilityState } = availabilitySlice.actions;

export const selectAvailability = (state) => state.availability;
export default availabilitySlice.reducer;
