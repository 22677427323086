import { useState } from 'react';

import { API_DEFAULT_SIZE } from '../../common/constants';
import Filters from '../filters/Filters';
import TableContext from './context';
import usePaging from './hooks/usePaging';

function useTable({ title, tableName, selectable, filterOptions, content, useQuery, additionalContextValues, useQueryArgs = {} }) {
	const [filters, setFilters] = useState({});
	const [tableControls, setTableControls] = useState({});
	const [search, setSearch] = useState();
	const [view, setView] = useState({});
	const [selectedRows, setSelectedRows] = useState([]);

	const args = {
		query: {
			...useQueryArgs.query, //arguments coming as useTable params
			...filters, //filters coming from filter menu
			...tableControls, //table controls (sort,page,size)
			search, //search rows term
			...view, //teble view settings coming from TableContext content component
		},
		path: {
			...useQueryArgs.path,
		},
		body: {
			...useQueryArgs.body,
		},
		variation: useQueryArgs.variation,
	};

	const { data = [], isLoading, isSuccess } = useQuery(args);
	//just use the data when there is no tablename
	const items = tableName ? data[tableName] : data;
	const totalRows = data?.count ?? 0;

	const [showing, paging, showingItems] = usePaging(items, totalRows);
	const goToFirstPage = paging?.actions?.goToFirst;

	const handleSetFilters = (newFilters) => {
		goToFirstPage();
		setSearch();
		setTableControls({});
		setFilters(newFilters);
	};

	const handleSetSearch = (newSearch) => {
		goToFirstPage();
		setSearch(newSearch);
		setTableControls({});
	};

	const handleSetView = (newView) => {
		goToFirstPage();
		setView(newView);
		setSearch();
		setTableControls({});
	};
	const manualSelectRows = (rowIds) => {
		setSelectedRows(rowIds);
	};
	const handleSelectRows = (rowIds) => {
		setSelectedRows((prevSelected) => {
			rowIds.forEach((rowId) => {
				const isSelected = prevSelected.includes(rowId);

				if (isSelected) {
					// If the element is already selected, remove it from the array
					prevSelected = prevSelected.filter((item) => item !== rowId);
				} else {
					// If the element is not selected, add it to the array
					prevSelected = [...prevSelected, rowId];
				}
			});

			return prevSelected;
		});
	};

	const filtersMenu = () => filterOptions && <Filters filters={filterOptions} onApply={handleSetFilters} onReset={handleSetFilters} />;

	const tableContextValues = {
		title,
		items: showingItems,
		totalRows,
		selectable,
		isLoading,
		isSuccess,

		showing,
		paging,
		querySize: API_DEFAULT_SIZE,

		tableControls: tableControls,
		onTableControlsChange: setTableControls,
		search,
		setSearch: handleSetSearch,
		view,
		onViewChange: handleSetView,
		manualSelectRows,
		selectedRows,
		selectRows: handleSelectRows,

		...additionalContextValues,
	};

	let table = <TableContext.Provider value={tableContextValues}>{content}</TableContext.Provider>;

	return {
		table,
		filtersMenu,
	};
}

export default useTable;
