import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { DateFormat, formatDate } from 'src/helpers/dates';

import { customStyles } from '../../../common/inputStyles';
import { Datepicker } from '../../../components/input';
import useModal from '../../../components/modal/useModal';
import { selectActiveBusiness, selectActiveStore } from '../../../reducers/business/businessSlice';
import { useGetStoreAvailabilityQuery } from '../../../reducers/reservation/reservationApiSlice';
import { useStoresQuery } from '../../../reducers/stores/storeApiSlice';
import { optionsPreferences } from '../../settings/stores/modal/details/StoreOptions';
import { defaultDurationOptions } from '../../settings/stores/modal/settingsComponents/settingsOptions';
import { partySizeOptions, sourceExtraOptions, sourceOptions, statusExtraOptions, statusOptions } from '../reservationPages/ResOptions';
import ReservationServiceAreas from './ReservationServiceAreas';

const animatedComponents = makeAnimated();
const ReservationDetails = ({ control, register, watch, setValue, store, isStoreLoading, errors }) => {
	const { editMode } = useModal();
	const { _id: business_id } = useSelector(selectActiveBusiness);
	const { _id: activeStoreId } = useSelector(selectActiveStore);
	const preferencesData = watch('preferences');
	const source = watch('source') ?? '';
	const date = watch('date') ?? new Date();
	const people = watch('people') ?? 2;
	const storeId = watch('store_id');
	const time = watch('time') ?? '';
	const duration = watch('duration_mins') ?? 120;
	// Stores
	const { data: stores } = useStoresQuery({
		query: { business_id: business_id },
	});

	const intl = useIntl();

	// Availability
	const { data: current = {}, isLoading: isAvailabilityLoading } = useGetStoreAvailabilityQuery({
		path: {
			id: storeId ?? activeStoreId,
		},
		people: people ?? 2,
		date: formatDate(date ?? new Date(), DateFormat.DAY_MONTH_YEAR_HYPHEN),
		source: source ?? 'walk-in',
		time: time,
		duration: duration,
	});
	// const service = useSelector(selectAvailability)?.availability;

	const hours = () => {
		// Set object without duplicated entries
		const timesSet = new Set();

		if (current?.availability && !isAvailabilityLoading) {
			// Unique items added to the set
			current?.availability.forEach((entry) => {
				timesSet.add(entry.time);
			});
			return Array.from(timesSet).map((t) => ({
				value: t,
				label: t,
			}));
		}

		return [];
	};

	const preferences =
		store && !isStoreLoading
			? store?.item?.reservation_prefs.map((item) => ({
					value: item?._id,
					label: item?.title,
			  }))
			: [];

	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="RESERVATIONS.LIST.ACTIONS.LABEL" />
					</div>
				</div>
			</div>
			<div className="row pb-5">
				<div className="col-12 col-sm-6">
					<Controller
						name="date"
						control={control}
						render={() => (
							<div className="col-12">
								<div className="fs-7 fw-bold">
									<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.DATE" />
									<sup>*</sup>
								</div>
								<Datepicker control={control} name="date" startDate={date} selected={date} />
							</div>
						)}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<Controller
						name="time"
						control={control}
						render={({ field }) => (
							<div className="col-12">
								<div className="pb-3 fs-7 fw-bold">
									<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.TIME" />
								</div>
								<select
									className="form-select form-select-solid text-capitalize"
									aria-label="Select example"
									{...register('time')}
									defaultValue={time}
									value={time ? time : field.value}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
									disabled={hours() && hours().length === 0}
								>
									{hours() && hours().length > 0
										? hours().map((hour, index) => (
												<option value={hour?.value} key={index}>
													{hour?.label}
												</option>
										  ))
										: null}
								</select>
							</div>
						)}
					/>
					{errors?.time ||
						!time ||
						time === '' ||
						(time === 'NaN:NaN' ? (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors?.time?.message}</span>
								</div>
							</div>
						) : null)}
				</div>
			</div>

			<div className="row pb-5">
				<Controller
					name="store_id"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.STORE" />
								<sup>*</sup>
							</div>
							<select
								className="form-select form-select-solid"
								aria-label="Select example"
								{...register('store_id')}
								defaultValue={storeId}
								value={storeId ? storeId : field.value}>
								{stores && stores.length > 0
									? stores?.map((store, index) => (
											<option value={store?._id} key={`store-${index}`}>
												{store?.title}
											</option>
									  ))
									: null}
							</select>
						</div>
					)}
				/>
				<Controller
					name="people"
					control={control}
					render={() => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.PARTY_SIZE" />
								<sup>*</sup>
							</div>
							<select className="form-select form-select-solid" aria-label="Please select party size" {...register('people')}>
								{partySizeOptions.map((party, index) => (
									<option value={party?.value} key={`party-size-option-${index}`}>
										{partySizeOptions[index].label}
									</option>
								))}
							</select>
						</div>
					)}
				/>
			</div>
			<div className="row pt-5">
				<Controller
					control={control}
					name="source"
					render={() => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.SOURCE" />
								<sup>*</sup>
							</div>
							<select
								className="form-select form-select-solid"
								aria-label="Please select source"
								{...register('source')}
								// disabled={source === 'mobile-app' || source === 'widget'}
							>
								{editMode
									? sourceExtraOptions.map((source, index) => (
											<option value={source?.value} key={`source-option-${index}`}>
												{sourceExtraOptions[index].label}
											</option>
									  ))
									: sourceOptions.map((source, index) => (
											<option value={source?.value} key={`source-option-${index}`}>
												{sourceOptions[index].label}
											</option>
									  ))}
							</select>
						</div>
					)}
				/>
				<Controller
					control={control}
					name="status"
					render={() => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.STATUS" />
								<sup>*</sup>
							</div>
							<select
								className="form-select form-select-solid text-capitalize"
								aria-label="Please select status"
								// disabled={status === 'rejected' || status === 'client_canceled' || status === 'store_canceled' || status === 'noshow'}
								{...register('status')}>
								{editMode
									? statusExtraOptions.map((status, index) => (
											<option value={status?.value} key={`status-option-${index}`}>
												{statusExtraOptions[index].label}
											</option>
									  ))
									: statusOptions.map((status, index) => (
											<option value={status?.value} key={`status-option-${index}`}>
												{statusOptions[index].label}
											</option>
									  ))}
							</select>
						</div>
					)}
				/>

				<Controller
					control={control}
					name="duration_mins"
					render={() => (
						<div className="col-12 col-sm-6 pt-5">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.DURATION" />
								<sup>*</sup>
							</div>
							<select className="form-select form-select-solid" aria-label="Please select status" {...register('duration_mins')}>
								{defaultDurationOptions.map((duration, index) => (
									<option value={duration?.value} key={`duration-option-${index}`}>
										{defaultDurationOptions[index].label}
									</option>
								))}
							</select>
						</div>
					)}
				/>
			</div>

			<div className="row pt-5 pb-5 z-99">
				<div className="col-12">
					<div className="input-group">
						<label htmlFor="preferences" className="pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="RESERVATIONS.DETAILS.MODAL_BODY.PREFERENCES" />
						</label>
						<Controller
							control={control}
							name="preferences"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									defaultValue={preferencesData ? preferencesData : preferences ? preferences.find((c) => value === c._id) : ''}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={preferences ? preferences : optionsPreferences}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
					</div>
				</div>
			</div>
			<ReservationServiceAreas register={register} control={control} watch={watch} errors={errors} setValue={setValue} />
		</>
	);
};

export default ReservationDetails;
