import clsx from 'clsx';

import { TableBody, TableCellActionItems } from './TableBody';
import { TableColumn, TableHeader } from './TableHeader';
import { TablePagination } from './TablePagination';
import TableSearch from './TableSearch';
import TableSelection from './TableSelection';
import TableToolbar from './TableToolbar';

const Table = ({ children, hasBorder = false }) => {
	return (
		<div className="table-responsive">
			<table className={clsx('table table-striped table-rounded table-row-bordered gy-5 gs-5 p-8', { 'table-border': hasBorder })}>
				<Table.Header>{children}</Table.Header>

				<Table.Body>{children}</Table.Body>
			</table>
		</div>
	);
};

// Compose components on table header
Table.Header = TableHeader;
Table.Column = TableColumn;
Table.Search = TableSearch;
Table.Selection = TableSelection;
Table.Toolbar = TableToolbar;

// Compose components on table body
Table.Body = TableBody;
Table.CellActionItems = TableCellActionItems;

// Compose components on table footer
Table.Pagination = TablePagination;
Table.CellActionItems = TableCellActionItems;

export default Table;
