import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import makeAnimated from 'react-select/animated';
import { BUSINESS_LANGUAGES } from 'src/common/constants';
import { customStyles } from 'src/common/inputStyles';
import useTableWithItems from 'src/components/table/useTableWithItems';
import { v4 as uuidv4 } from 'uuid';

import useModal from '../../../../components/modal/useModal';
import { setShowModal } from '../../../../reducers/modalSlice';
import LocationBookingGuestsDropdown from './openingHoursComponents/LocationBookingGuestsDropdowns';
import LocationDayHoursDropdowns from './openingHoursComponents/LocationDayHoursDropdowns';
import OpeningHoursFormModal from './openingHoursComponents/OpeningHoursFormModal';
import { dayOptions } from './openingHoursComponents/openingHoursOptions';
import OpeningHoursTable from './openingHoursComponents/OpeningHoursTable';

const animatedComponents = makeAnimated();

const TabOpeningHours = ({ control, register, trigger, watch, errors, resetField }) => {
	// const dispatch = useDispatch();
	let data = {};
	const openingHoursData = watch('opening_hours');
	const [editingHour, setEditingHour] = useState({});
	const [isEditMode, setEditMode] = useState(false);

	const handleEditHour = (id) => {
		setShowInputFields(false);
		setEditMode(true);
		const options = data?.items ?? selectedOptions?.options;
		const dayToEdit = options.find((hour) => hour._id === id);
		setDay(dayToEdit.day.toLowerCase().slice(0, 3));

		setTimeout(() => {
			setShowInputFields(true);
			// dispatch(
			// 	setShowModal({
			// 		type: 'locationOpeningHours'
			// 	})
			// );
		}, 100);
	};

	const handleDeleteHour = (id) => {
		for (let key in openingHoursData) {
			if (openingHoursData[key]._id === id) {
				delete openingHoursData[key];
				trigger(['opening_hours']);
				setShowInputFields(false);
			}
		}
	};

	const [openDropdown, setOpenDropdown] = useState('');
	const [showInputFields, setShowInputFields] = useState(false);
	const [tempSelectedOptions, setTempSelectedOptions] = useState({});
	const [selectedOptions, setSelectedOptions] = useState({
		options: [],
		count: 0,
	});

	const updateSelectedOptions = (event) => {
		event.preventDefault();

		const { selectedDay, selectedFromTime, selectedToTime } = tempSelectedOptions;

		if (Object.keys(editingHour).length > 0) {
			setSelectedOptions((prevOptions) => {
				const updatedOptions = prevOptions.options.map((hour) =>
					hour.id === editingHour.id ? { ...hour, selectedDay, selectedFromTime, selectedToTime } : hour
				);

				return {
					options: updatedOptions,
					count: updatedOptions.length,
				};
			});

			setEditingHour({});
		} else {
			setSelectedOptions((prevOptions) => {
				const newEntry = { id: uuidv4(), selectedDay, selectedFromTime, selectedToTime };
				const updatedOptions = [...prevOptions.options, newEntry];
				return {
					options: updatedOptions,
					count: updatedOptions.length,
				};
			});
		}

		setTempSelectedOptions({
			selectedDay: '',
			selectedFromTime: '',
			selectedToTime: '',
		});

		setShowInputFields(false);
	};

	const handleAddOpeningHourButton = () => {
		setEditMode(false);
		setShowInputFields(true);
	};

	const handleCancelButton = () => {
		setEditMode(false);
		setShowInputFields(false);
		setEditingHour({});
		resetField('opening_hours', { defaultValue: openingHoursData });
	};

	data = {
		items: openingHoursData ? Object.values(openingHoursData) : 0,
		count: openingHoursData ? openingHoursData.length : 0,
	};

	const intl = useIntl();
	const tableValues = {
		title: intl.formatMessage({ id: 'STORE.TABLE' }) ?? 'Opening Hours',
		items: data?.items ? data?.items : selectedOptions.options,
		totalRows: data?.count ? data?.count : selectedOptions.count,
		content: <OpeningHoursTable onEditHour={handleEditHour} onDeleteHour={handleDeleteHour} />,
	};

	const { table } = useTableWithItems(tableValues);
	const [day, setDay] = useState(dayOptions[0].value);
	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1">
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							{showInputFields && !isEditMode ? (
								<FormattedMessage id="LOCATION.MODAL.CREATE.OPENING_HOURS.TITLE" />
							) : showInputFields && isEditMode ? (
								<FormattedMessage id="LOCATION.MODAL.EDIT.OPENING_HOURS.TITLE" />
							) : (
								<FormattedMessage id="STORE.MODAL.OPENING_HOURS" />
							)}
						</div>
					</div>
				</div>

				{!showInputFields ? table : null}
				{data.items.length !== 7 && !showInputFields ? (
					<div className="row">
						<div className="col-12 col-sm-4">
							<button
								type="button"
								className="generic-button fs-6 w-100 fw-bold"
								onClick={handleAddOpeningHourButton}
								disabled={showInputFields === true}
							>
								<FormattedMessage id="LOCATION.OPENING_HOURS_TAB.ADD_HOUR" />
								<sup>*</sup>
							</button>
						</div>
						{errors?.opening_hours && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.opening_hours.message}</span>
								</div>
							</div>
						)}
					</div>
				) : null}

				{showInputFields ? (
					<OpeningHoursFormModal
						register={register}
						control={control}
						day={day}
						watch={watch}
						updateSelectedOptions={updateSelectedOptions}
						tempSelectedOptions={tempSelectedOptions}
						handleCancelButton={handleCancelButton}
					/>
				) : null}
			</form>
		</div>
	);
};

export default TabOpeningHours;
