/* eslint-disable prefer-template */
import apiSlice, { API_ROOT } from '../app/services/apiSlice';

const basePath = '/files';

const fileApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		files: builder.query({
			query: (id) => (id ? `/files/${id}` : '/files'),
			providesTags: ['Files'],
		}),
		uploadFiles: builder.mutation({
			query: (formData) => ({
				url: basePath + '/upload',
				method: 'POST',
				body: formData,
			}),
		}),
		uploadImage: builder.mutation({
			query: (formData) => ({
				url: API_ROOT + basePath + '/upload-image',
				method: 'POST',
				body: formData,
			}),
		}),
	}),
});

const { useFilesQuery, useUploadFilesMutation, useUploadImageMutation } = fileApiSlice;

export { useFilesQuery, useUploadFilesMutation, useUploadImageMutation };
