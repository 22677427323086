import { injectTags } from 'src/pages/analytics/tag/api/tagApiSlice';

import apiSlice, { baseQueryWithReauth } from '../app/services/apiSlice';
import prepareURL from '../common/prepareURL';

const basePath = '/contacts';
const getVariationPath = (variation) => {
	switch (variation) {
		case 'customers':
			return basePath + '/customers';
		case 'leads':
			return basePath + '/leads';
		case 'contacts':
		default:
			return basePath;
	}
};

const prepareConactsURL = (args, resource = '') => {
	const path = getVariationPath(args.variation);
	return prepareURL(path + resource, args);
};

const contactApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		contacts: builder.query({
			query: (args) => prepareConactsURL(args),
			providesTags: (result, error) => {
				const tags = [{ type: 'Contact', id: 'PARTIAL-LIST' }];

				if (!error && result?.contacts && result.contacts.length > 0) {
					const contactsTags = result.contacts.map(({ _id: id }) => ({ type: 'Contact', id }));
					return tags.concat(contactsTags);
				}

				return tags;
			},
		}),
		contactsAndTags: builder.query({
			async queryFn(args, api, extraOptions) {
				//const tags = await fetchBaseQuery(getTags, args, extraOptions, loader)();

				let result = await baseQueryWithReauth({ url: prepareConactsURL(args) }, api, extraOptions);
				if (result.isScuccess === false) {
					return result;
				}
				const contacts = result.data.contacts;
				try {
					const contactsWithTags = await injectTags({ items: contacts, api, extraOptions });
					return result;
				} catch (error) {
					return result;
				}
			},
			providesTags: (result, error) => {
				const tags = [{ type: 'Contact', id: 'PARTIAL-LIST' }];

				if (!error && result?.contacts && result.contacts.length > 0) {
					const contactsTags = result.contacts.map(({ _id: id }) => ({ type: 'Contact', id }));
					return tags.concat(contactsTags);
				}

				return tags;
			},
		}),
		contact: builder.query({
			query: (args) => prepareConactsURL(args, '/{id}'),
			providesTags: (result, error) => {
				if (!error && result?.contact) {
					return [{ type: 'Contact', id: result?.contact._id }];
				}
				return [];
			},
		}),
		changeContactType: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}/type'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
		}),
		changeContactDeactivation: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}/deactivation'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
		}),
		createContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args),
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: ['Contact'],
		}),
		updateContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}'),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
		}),
		deleteContact: builder.mutation({
			query: (args) => ({
				url: prepareConactsURL(args, '/{id}'),
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, args) => {
				const { success } = result;
				if (success && error === undefined) {
					const { id } = args.path;

					return [
						{ type: 'Contact', id },
						{ type: 'Contact', id: 'PARTIAL-LIST' },
					];
				}
			},
			refetchOn: 'fulfilled',
		}),
		searchContacts: builder.query({
			query: (args) => prepareConactsURL(args, '/search'),
		}),
	}),
});

const {
	useContactsQuery,
	useContactQuery,
	useLazyContactQuery,
	useChangeContactTypeMutation,
	useChangeContactDeactivationMutation,
	useCreateContactMutation,
	useUpdateContactMutation,
	useDeleteContactMutation,
	useContactsAndTagsQuery,
	useSearchContactsQuery,
} = contactApiSlice;

export {
	useChangeContactDeactivationMutation,
	useChangeContactTypeMutation,
	useContactQuery,
	useContactsAndTagsQuery,
	useContactsQuery,
	useCreateContactMutation,
	useDeleteContactMutation,
	useLazyContactQuery,
	useSearchContactsQuery,
	useUpdateContactMutation,
};
