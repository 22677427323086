import apiSlice from '../app/services/apiSlice';
import { BUSINESS_LANGUAGES } from '../common/constants';
import prepareURL from '../common/prepareURL';
import { setAreasState } from './areas/areasSlice';

const basePath = 'business/{bid}/store/{sid}/area';

const areaApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		areas: builder.query({
			query: (args) => prepareURL(basePath, args),
			transformResponse: (response) => {
				const { areas } = response;
				return areas;
			},
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setAreasState(data));
				} catch (err) {
					// `onError`
				}
			},
			providesTags: (result, error) => {
				const tags = [{ type: 'Areas', id: 'PARTIAL-LIST' }];

				if (!error && result && result.length > 0) {
					const areasTags = result.map(({ _id: id }) => ({ type: 'Areas', id }));
					return tags.concat(areasTags);
				}

				return tags;
			},
		}),
		getArea: builder.query({
			query: (inputs) => {
				const { args } = inputs;
				return prepareURL(basePath + '/{aid}', args);
			},
			transformResponse: (response) => {
				const { area } = response;

				const transformedArea = {
					area: [{ lng: BUSINESS_LANGUAGES[0].code, text: area.title }],
					bookable: area.bookable,
					availableOnline: area.online,
				};

				if (area?.priority) {
					transformedArea.bookingPriority = { value: area.priority, label: area.priority };
				}

				transformedArea.guestNotes = [];
				if (area?.guest_note) {
					transformedArea.guestNotes.push({ lng: area.default_locale, text: area.guest_note });
				}

				BUSINESS_LANGUAGES.slice(1).forEach(({ code }) => {
					const translationKeys = Object.keys(area?.translations || []);

					if (translationKeys.includes(code)) {
						transformedArea.area.push({ lng: code, text: area.translations[code].title || '' });
						transformedArea.guestNotes.push({ lng: code, text: area.translations[code].guest_note || '' });
					} else {
						if (area.default_locale !== code) transformedArea.area.push({ lng: code, text: '' });
						if (!area?.guest_note || area.default_locale !== code) transformedArea.guestNotes.push({ lng: code, text: '' });
					}
				});

				return transformedArea;
			},
			providesTags: (result, error, args) => [{ type: 'Areas', id: args.aid }],
		}),
		createArea: builder.mutation({
			query: (inputs) => {
				const {
					body,
					params: { args },
				} = inputs;

				return {
					url: prepareURL(basePath, args),
					method: 'POST',
					body,
				};
			},
			invalidatesTags: [{ type: 'Areas', id: 'PARTIAL-LIST' }],
		}),
		editArea: builder.mutation({
			query: (inputs) => {
				const {
					body,
					params: { args },
				} = inputs;

				return {
					url: prepareURL(basePath + '/{aid}', args),
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (result, error, args) => {
				if (result?.status === 'success' && !error) {
					return [
						{ type: 'Areas', id: args.aid },
						{ type: 'Areas', id: 'PARTIAL-LIST' },
					];
				}
				return [];
			},
		}),
		deleteArea: builder.mutation({
			query: (args) => {
				return {
					url: prepareURL(basePath + '/{aid}', args),
					method: 'DELETE',
				};
			},
			invalidatesTags: (result, error, args) => {
				if (result?.status === 'success' && !error) {
					return [
						{ type: 'Areas', id: args.aid },
						{ type: 'Areas', id: 'PARTIAL-LIST' },
					];
				}
				return [];
			},
		}),
		getAreaTable: builder.query({
			query: (inputs) => {
				const { args } = inputs;
				return prepareURL(basePath + '/{aid}/table/{tid}', args);
			},
			transformResponse: (response) => {
				const { table } = response;

				const transformedAreaTable = {
					table: [{ lng: BUSINESS_LANGUAGES[0].code, text: table.title }],
					minSeats: table.min_seats,
					maxSeats: table.max_seats,
					bookable: table.bookable,
					availableOnline: table.online,
				};

				if (table?.priority) {
					transformedAreaTable.bookingPriority = { value: table.priority, label: table.priority };
				}

				BUSINESS_LANGUAGES.slice(1).forEach(({ code }) => {
					const translationKeys = Object.keys(table?.translations || []);

					if (translationKeys.includes(code)) {
						transformedAreaTable.table.push({ lng: code, text: table.translations[code].title || '' });
					} else {
						if (table.default_locale !== code) transformedAreaTable.table.push({ lng: code, text: '' });
					}
				});

				return transformedAreaTable;
			},
			providesTags: (result, error, args) => [{ type: 'AreaTables', id: args.tid }],
		}),
		createAreaTable: builder.mutation({
			query: (inputs) => {
				const {
					body,
					params: { args },
				} = inputs;

				return {
					url: prepareURL(basePath + '/{aid}/table', args),
					method: 'POST',
					body,
				};
			},
			invalidatesTags: [{ type: 'Areas', id: 'PARTIAL-LIST' }],
		}),
		editAreaTable: builder.mutation({
			query: (inputs) => {
				const {
					body,
					params: { args },
				} = inputs;

				return {
					url: prepareURL(basePath + '/{aid}/table/{tid}', args),
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: [{ type: 'Areas', id: 'PARTIAL-LIST' }],
		}),
		deleteAreaTable: builder.mutation({
			query: (inputs) => {
				return {
					url: prepareURL(basePath + '/{aid}/table/{tid}', inputs),
					method: 'DELETE',
				};
			},
			invalidatesTags: [{ type: 'Areas', id: 'PARTIAL-LIST' }],
		}),
	}),
});

export const {
	useAreasQuery,
	useGetAreaQuery,
	useCreateAreaMutation,
	useEditAreaMutation,
	useDeleteAreaMutation,
	useGetAreaTableQuery,
	useCreateAreaTableMutation,
	useEditAreaTableMutation,
	useDeleteAreaTableMutation,
} = areaApiSlice;
