import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { maxSimilarBookingsOptions } from './settingsOptions';

const RestFieldsSettings = (props) => {
	const { register, watch, control } = props;
	const maxSimilar = watch('online_booking_settings.max_similar') ?? 10;
	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="LOCATION.SETTINGS.HEADER" />
					</div>
				</div>
			</div>

			<div className="row">
				<Controller
					name="online_booking_settings.max_similar"
					defaultValue={maxSimilar}
					control={control}
					render={({ field }) => (
						<div className="col-12">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.MAX_SIMILAR_BOOKINGS" />
							</div>
							<select
								className="form-select form-select-solid"
								aria-label="Select example"
								{...register('online_booking_settings.max_similar')}
							>
								{maxSimilarBookingsOptions.map((bookingOption) => (
									<option key={bookingOption.value} value={bookingOption.value} defaultValue={bookingOption.value}>
										{bookingOption.label}
									</option>
								))}
							</select>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.MAX_SIMILAR_BOOKINGS_TXT" />
							</p>
						</div>
					)}
				/>
			</div>
		</>
	);
};

export default RestFieldsSettings;
