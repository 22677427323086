import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './app/routing/AppRoutes';
import { useGetAppSectionsQuery } from './app/services/apiSlice';
import store from './app/store';
import { useSelectQuery } from './components/input';
import { useAreasQuery } from './reducers/areaApiSlice';
import { selectActiveBusiness, selectActiveStore } from './reducers/business/businessSlice';

const container = document.getElementById('root');
export const DataProvider = ({ children }: { children: React.ReactNode }) => {
	useGetAppSectionsQuery({});

	const { _id: bid } = useSelector(selectActiveBusiness);
	const { _id: activeStoreId } = useSelector(selectActiveStore);
	useSelectQuery({
		responseOptions: {
			idField: '_id',
			labelField: 'title',
			path: { bid: bid, sid: activeStoreId },
		},
		useQuery: useAreasQuery,
	});
	return <>{children}</>;
};

if (container) {
	const root = createRoot(container);

	root.render(
		<StrictMode>
			<Provider store={store}>
				<DataProvider>
					<BrowserRouter>
						{/* <ErrorBoundary> */}
						<AppRoutes />
						{/* </ErrorBoundary> */}
					</BrowserRouter>
				</DataProvider>
			</Provider>
		</StrictMode>
	);
}
