import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useTableWithItems from '../../../components/table/useTableWithItems';
import ReservationPaymentsTable from '../payments/ReservationPaymentsTable';

const TabPayments = ({ watch, setValue }) => {
	const payments = watch('payments');
	const [selectedOptions, setSelectedOptions] = useState({
		options: [],
		count: 0,
	});
	const tableValues = useMemo(() => {
		return {
			title: 'Payments',
			items: payments ? payments : selectedOptions?.options,
			totalRows: payments?.length ? payments?.length : selectedOptions.count,
			content: <ReservationPaymentsTable watch={watch} setValue={setValue} />,
		};
	}, [payments]);

	const { table } = useTableWithItems(tableValues);
	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1">
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							<FormattedMessage id="RESERVATION.PAYMENTS_TABLE.TITLE" />
						</div>
					</div>
				</div>
				{table}
			</form>
		</div>
	);
};
export default TabPayments;
