import React from 'react';
import { FormattedMessage } from 'react-intl';

const ReservationNotes = ({ register, watch }) => {
	const teamNotes = watch('team_notes');
	const messageToGuests = watch('message_to_guests');
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="RESERVATIONS.MODAL_BODY.TEAM_NOTES" />
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<label htmlFor="team_notes" className="pb-3 fs-7 fw-bold">
						<FormattedMessage id="RESERVATIONS.MODAL_BODY.NOTES" />
					</label>
					<textarea
						className="full-width-textarea fs-6 p-4"
						aria-labelledby="teamNotes"
						rows={4}
						cols={50}
						{...register('team_notes')}
						defaultValue={teamNotes}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="RESERVATIONS.MODAL_BODY.MESSAGE_TO_GUEST" />
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<label htmlFor="messages" className="pb-3 fs-7 fw-bold">
						<FormattedMessage id="RESERVATIONS.MODAL_BODY.MESSAGE" />
					</label>
					<textarea
						className="full-width-textarea fs-6 p-4"
						aria-labelledby="messages"
						rows={4}
						cols={50}
						{...register('message_to_guest')}
						defaultValue={messageToGuests}
					/>
				</div>
			</div>
		</>
	);
};

export default ReservationNotes;
